import { AssetImage } from 'src/entities/assetImage';
import { Component } from '@angular/core';
import { LOGO } from 'src/config/constats';
import { WAITING_LIST } from 'src/config/images';
import { WaitlistService } from 'src/app/service/waitlist.service';
import { UrlService } from 'src/app/service/url.service';
import { WaitlistStatusResposne } from 'src/entities/waitlist';
import { StorageService } from 'src/app/service/storage.service';
import { RouteService } from 'src/app/service/route.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-waiting-list-check-turn',
  templateUrl: './check-turn.component.html',
  styleUrls: ['./check-turn.component.scss']
})
export class CheckTurnComponent {
  logoUrl: string = LOGO;
  assetImg:AssetImage = WAITING_LIST;
  isYourTurn:boolean = false;
  position:number = 0; // unused
  reparto: string = '';
  vendor: string = '';
  textSuccess = '';
  constructor(
    private _waitlistService: WaitlistService,
    private _urlService: UrlService,
    private _storageService: StorageService,
    private _routeService: RouteService,
    public _translate: TranslateService,
  ) {}

 ngOnInit() {
  const checkCondition = (response: WaitlistStatusResposne) => response.assignedUser != '';
  let url = '';
  const data = this._waitlistService.getDataWl();
  if (!data) this._routeService.goTo('lista-attesa');
  if (data && data.token){ 
    url = `${this._urlService.getUrl('waitlistStatus')}${data.token}`;
  }
  this._waitlistService.pollUntilConditionMet(url, checkCondition, 7000).subscribe({
    next: (response) => {
      this.vendor = (response.assignedUser != null) ? response.assignedUser : '';
      console.log('Polling response:', response);
    },
    error: (err) => {
      console.error('Error during polling:', err);
      this._storageService.removeItem('isGuest');
    },
    complete: () => {
      
      this._storageService.removeItem('isGuest');
      this.reparto = data?.department || '';
      this.isYourTurn = true;
      console.log('Polling completed.');
      this._translate.get('waitingList.checkTurn.subtitleSuccess', { reparto: this.reparto, vendor: this.vendor }).subscribe((res: string) => {
        this.textSuccess = res;
      });
    }
  });
 }
}
