import {
  ACCEPTED_STATE,
  DES_ADDEBITO_TRASPORTO_2,
  DES_ADDEBITO_TRASPORTO,
  DES_COSTI_GIACENZA,
  DES_DOLCE_CASA,
  DES_GEOMETRA,
  DES_NAVETTA,
  DES_RIPROGRMMAZIONE,
  DES_RITIRO_MERCE,
  DES_SISTEMAZIONE_2,
  DES_SISTEMAZIONE,
  DES_VIAGGIO,
  NAVETTA,
} from "src/config/constats";

import { Address } from "src/entities/address";
import { AddressService } from "./address.service";
import { Detail } from "src/entities/detail";
import { Injectable } from "@angular/core";
import { Order } from "src/entities/order";
import { ReservationService } from "./reservation.service";
import { UtilityService } from "./utility.service";
import { Voucher } from "src/entities/voucher";
import { VouchersService } from "./vouchers.service";
import { GuestService } from "./guest.service";

interface RefactoringAttrOrdiniParams {
  orders: Order[];
  infoLogg?: any;
  isGuest?: boolean;
}

@Injectable({
  providedIn: 'root',
})

export class OrdiniUtiltyService {
  reservationBlocked:Map<string, Record<string, boolean>> = new Map<string, Record<string, boolean>>();
  newObjOrders: Order[] = [];
  buoniSpesa: Voucher[] = [];

  constructor(
    private _addressService: AddressService,
    private _utilityService: UtilityService,
    private _reservationService: ReservationService,
    private _vouchersService: VouchersService,
    private _guestService: GuestService,
  ) {
  }
  
  public refactoringObjOrders(orders: Order[]) {
    this.newObjOrders = [];
    let addresses: Address[] = [];
    let progressiveId = 1;
    
    if (orders.length == 0) {
      this._addressService.addresses$.next([]);
      this._addressService.addressIsEmpty$.next(true);
      return [];
    }

    this._reservationService.cleanReservation();
    orders.forEach((o: any) => {
      if (o.ordini.length == 0) return;
      addresses.push({
        "id": progressiveId,
        "cap": o.cap,
        "civico": o.civico,
        "cog": o.cog,
        "comune": o.comune,
        "ind": o.ind,
        "indlib": o.indlib,
        "indplus": o.indplus,
        "nom": o.nom,
        "prov": o.prov,
      });

      const firstLevel:any = Object.fromEntries(
        Object.entries(o).filter(([key]) => key !== 'ordini')
      );
      o.ordini = this.refactoringAttrOrdini({
          orders: o.ordini,
          infoLogg: firstLevel
        });
      progressiveId++;
    });


    this.setReservationBlockedList(this.newObjOrders);

    this._addressService.addresses$.next(addresses.map((e:Address) => {
      return {
        ...e,
        address: this._utilityService.setAddress({
          ind: e.ind,
          indlib: e.indlib,
          civico: e.civico,
        })
      }
    }));
    if (addresses.length === 0) this._addressService.addressIsEmpty$.next(true);

    this.setVoucherObservable();

    return this.newObjOrders;
  }

  setReservationBlockedList(orders: Order[]) {
    const reservationBlockedList = this.sanitazeReservationBlocked(this.reservationBlocked);
    if(reservationBlockedList.size > 0) {
      reservationBlockedList.forEach((record, key) => {
        const isBlock = Object.entries(record).some(([k, v]) => {
          return !v;
        });
        orders.forEach((oe: Order) => {
          if (key == oe.prbu){
            this._reservationService.removeReservation(oe.prbu+oe.rev);
            oe.blockReservation = isBlock;
          }
        });
      });
    }

    return orders;
  }

  refactoringAttrOrdini({
    orders, 
    infoLogg,
    isGuest = false
  }: RefactoringAttrOrdiniParams) {
    orders.forEach((oe: Order) => {
      if (oe.dettagli.length > 0) {
        const statoKey = oe.stato;
        const consegnaKey = oe.consegna;

        /** Forzo l'assegnazione per lo stato navetta */
        if ((oe.consdes).toLowerCase() === NAVETTA)  oe.consegna = 'N';
        if (oe.codmcdep == 900) oe.isDropOff = true;
        if (oe.codmcdep == 47) oe.isCatanzaroDep = true;
        oe.catesterno = (oe.catesterno === undefined) ? false : oe.catesterno;
    
    
        oe.canale = this._utilityService.getCanale(oe.canale, oe.subcanale);
        oe.stato = this._utilityService.getStato({
          k: oe.stato,
          consegna: oe.consegna,
          dccorr: this._utilityService.dateConverter(oe.dccorr),
          perapp: oe.perapp,
          dcc: this._utilityService.dateConverter(oe.dcc),
          dapp: this._utilityService.dateConverter(oe.dapp),
          dcci: this._utilityService.dateConverter(oe.dcci),
          fasciacom: oe.fasciacom,
          cfgold: oe.cfgold
        });
    
        oe.consegna = this._utilityService.getConsegna(oe.consegna);

        // itero sul dettaglio di ogni singolo ordine
        this.iterateDettaglio(oe);

        const objOrder = {
          ...infoLogg,
          ...oe,
          "statoKey": statoKey,
          "consegnaKey": consegnaKey,
          "blockReservation": false,
        }

        if (isGuest) objOrder.guid = this._guestService.getGuid();

        this.newObjOrders.push(objOrder);
        
        const isPerapp = this._utilityService.getShowCreateAppointment({
          order:objOrder, 
          isBoxOrder: true,
          isGuest
        });  

        const conditions = objOrder.statoKey != ACCEPTED_STATE[6] && objOrder.statoKey != ACCEPTED_STATE[5] && !objOrder.annullato;
        if (conditions) this.addValueToKey(objOrder.prbu, objOrder.rev, isPerapp);
        if (conditions && isPerapp && !objOrder.blockReservation) this._reservationService.setReservation(objOrder.prbu+objOrder.rev);
        if (objOrder.buonispesa.length > 0) this.setVouchers(objOrder.buonispesa, `${objOrder.prbu}${objOrder.rev}`);
      } else {
        oe.dettagli = new Array(new Detail);
      }
    })

    return this.newObjOrders;
  }

  iterateDettaglio (oe: Order) {
    let elementi: Detail[] = [];
    let isCcod = false;
    if (oe.dettagli && oe.dettagli.length > 0) {
      oe.dettagli.forEach((d: Detail) => {
        if (d.ccod && !isCcod) isCcod = true;
        if (!d.ccod && isCcod) elementi.push(d);

        if ((d.des).toLowerCase() === DES_NAVETTA)  oe.navetta = true;
        if ((d.des) === DES_RIPROGRMMAZIONE)  oe.isRiprogrammazione = true;
        if ((d.des).toLowerCase() === DES_SISTEMAZIONE ||
        (d.des).toLowerCase() === DES_SISTEMAZIONE_2) oe.isSistemazione = true;
        if ((d.des).toLowerCase().includes(DES_COSTI_GIACENZA)) oe.isCostiGiacenza = true;
        if ((d.des).toLowerCase() === DES_RITIRO_MERCE) oe.isRitiroMerce = true;
        if ((d.des).toLowerCase().includes(DES_GEOMETRA)) oe.isGeometra = true;
        if ((d.des).toLowerCase() === DES_DOLCE_CASA) oe.isDolceCasa = true;
        if (((d.des).toLowerCase() === DES_ADDEBITO_TRASPORTO || (d.des).toLowerCase() === DES_ADDEBITO_TRASPORTO_2) && !d.trasporto) oe.isAddebitoTrasporto = true;
        if ((d.des).toLowerCase() === DES_VIAGGIO) oe.isTraghetto = true;
        d.consegna = this._utilityService.getConsegna(d.consegna);
        d.imageUrl = this._utilityService.assignImageToProduct(d, oe);
      })

      if (elementi.length > 0 && oe.dettagli) {
        oe.dettagli[0].elementi = elementi;
        const firstDettaglio = oe.dettagli[0];
        oe.dettagli = [];
        oe.dettagli[0] = firstDettaglio;
      }
    }
  }

  addValueToKey(prbu: string, rev: string, perapp: boolean): void {
    if (+rev == 0 || this._utilityService.checkIsRevisione(rev)) {
      if (this.reservationBlocked.has(prbu)) {
        const existingRecord = this.reservationBlocked.get(prbu);
        if (existingRecord) {
          existingRecord[rev] = perapp;
          this.reservationBlocked.set(prbu, existingRecord);
        }
      } else {
        this.reservationBlocked.set(prbu, { [rev]: perapp });
      }
    }
  }

  sanitazeReservationBlocked(
    inputMap: Map<string, Record<string, boolean>>
  ): Map<string, Record<string, boolean>> {
    const result = new Map<string, Record<string, boolean>>();
    inputMap.forEach((innerMap, topKey) => {
      const entries = Object.entries(innerMap);
      
      if (entries.length >= 2) {
        let hasTrue = false;
        const updatedInnerMap: Record<string, boolean> = {};
        entries.forEach(([key, value]) => {
          if (value) {
            hasTrue = true;
          }
          updatedInnerMap[key] = value;
        });
      
        if (hasTrue) {
          result.set(topKey, updatedInnerMap);
        }
      }
    });
  
    return result;
  }

  setVouchers(attrOrderBuoniSpesa: Voucher[], bc:string) {
    attrOrderBuoniSpesa.map((voucher: Voucher) => {
      voucher.idbc = bc;
      this.buoniSpesa.push(voucher);
    })
  }

  setVoucherObservable() {
    if (this.buoniSpesa.length > 0) this._vouchersService.setVouchers$.next(this.buoniSpesa);
  }
}

