import { ACCEPTED_STATE } from 'src/config/constats';
import { ApiService } from '../service/api.service';
import { BarcodeService } from '../service/modal/barcode.service';
import { Component, Input, OnInit, ViewContainerRef } from '@angular/core';
import { DeviceService } from '../service/device.service';
import { FunzioneDisabilitataService } from '../service/modal/funzioneDisabilitata.service';
import { GtmService } from '../service/gtm.service';
import { Invoice } from 'src/entities/invoice';
import { Order } from 'src/entities/order';
import { Router } from '@angular/router';
import { RouteService } from '../service/route.service';
import { UtilityService } from '../service/utility.service';

@Component({
  selector: 'app-orders-actions',
  templateUrl: './orders-actions.component.html',
  styleUrls: ['./orders-actions.component.scss']
})
export class OrdersActionsComponent implements OnInit {
  @Input() data!: Order;
  @Input() isDetail!: boolean;
  @Input() invoice: Invoice[] | null = null;

  isAfterOrder: boolean = false
  isMobile: boolean = false;
  showBarcodeButton: boolean = false;
  showButtonAppointment: boolean = false;
  showButtonAppointmentEdit: boolean = false;
  // showButtonCompleted: boolean = false;
  showButtonDeleted: boolean = false;
  showButtonDetail: boolean = false;
  showModal: boolean = false;

  dapp: string | null = '';
  orderId: string = '';
  rev: string = '';

  constructor(
    private _barcodeService: BarcodeService,
    private _funzioneDisabilitataService: FunzioneDisabilitataService,
    private _gtmService: GtmService,
    private _routeService: RouteService,
    private _utilityService: UtilityService,
    private router: Router,
    private viewContainerRef: ViewContainerRef,
    public _apiService: ApiService,
    public _detectorService: DeviceService,
  ) {
    this.isMobile = this._detectorService._isMobile.getValue();
  }

  ngOnInit(): void {
    /** base data from input */
    const {
      annullato,
      catesterno,
      consegnaKey,
      dapp,
      dbu,
      perapp,
      prbu,
      rev,
      saldo,
      statoKey,
      dateutili,
      isCatanzaroDep,
      isRevisionWithAppointment,
      nazione
    } = this.data;

    this.dapp = dapp;
    this.orderId = prbu + rev;
    this.rev = rev;

    /** from base data  */
    this.showBarcodeButton = consegnaKey == 'R' && saldo == 0 && this.isDetail

    this.isAfterOrder = this._utilityService.isOrderAfterGoLive(dbu);


    /** Pulsante fissa/modifica appuntamento*/
    this.showButtonAppointment = this._utilityService.getShowCreateAppointment(this.data,false, this._apiService.isGuest$.getValue());

    /** Pulsante modifica appuntamento */
    this.showButtonAppointmentEdit = this._utilityService.getShowEditAppointment(this.data, false, this._apiService.isGuest$.getValue());

    // this.showButtonCompleted = !this.isDetail && statoKey == ACCEPTED_STATE[6];
    this.showButtonDeleted = statoKey == ACCEPTED_STATE[5] || annullato;
    this.showButtonDetail = !this.isDetail && statoKey != ACCEPTED_STATE[5] && !annullato && !this.isMobile;
  }

  openModalFunzioneDisabilitata(e: Event) {
    e.preventDefault();
    this._funzioneDisabilitataService.setRootViewContainerRef(this.viewContainerRef);
    this._funzioneDisabilitataService.addDynamicComponent();
  }

  openModalBarcode(e: Event) {
    e.preventDefault();
    this._barcodeService.setRootViewContainerRef(this.viewContainerRef);
    this._barcodeService.addDynamicComponent(this.orderId);
  }

  goToDetailOrder () {
    this.router.navigate(['/orderDetail', this.orderId]);
  };

  goToReservation () {
    this.router.navigate(['/reservation', this.orderId]);
  }

  changeShowModal() {
    this.showModal = !this.showModal;
  }

  closeModal() {
    this.showModal = false;
  }

  goToSignUp() {
    this._routeService.goTo('signup');
  }

  goToSignIn() {
    this._routeService.goTo('login');
  }

  cbkGtmDownloadInvoice() {
    this._gtmService.eventDownloadInvoice();
  }

  cbkGtmRequestInvoice() {
    this._gtmService.eventInvoice();
  }
}
