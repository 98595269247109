import { ApiService } from "./api.service";
import { AutoLoginVsMagento } from "./autoLoginVsMag.service";
import { CustomCookieService } from "./customCookie.service";
import { DomSanitizer } from '@angular/platform-browser';
import { HeadersService } from "./headers.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { throwError, catchError, map, shareReplay } from "rxjs";
import { TokenService } from "./token.service";
import { UrlService } from "./url.service";
import { UserService } from "./user.service";
import { 
  GOOGLE_URL_VALIDATION_TOKEN, GOOGLE_APP_ID, 
  FACEBOOK_APP_ID,
  FACEBOOK_APP_TOKEN,
  FACEBOOK_URL_VALIDATION_TOKEN 
} from 'src/config/social';
import { GoogleTokenInfo } from "src/entities/GoogleTokenInfo";
import { User } from "src/entities/user";

@Injectable({
  providedIn: 'root'
})

export class LoginService {
  headers: HttpHeaders;

  constructor(
    private _apiService: ApiService,
    private _autoLoginVsMagService: AutoLoginVsMagento,
    private _customCookieService: CustomCookieService,
    private _headersService: HeadersService,
    private _tokenService: TokenService,
    private _urlService: UrlService,
    private _userService: UserService,
    private http: HttpClient,
    private router: Router,
    private sanitizer: DomSanitizer
  ) {
    this.headers = this._headersService.getHeaders();
  }

  /**
   * User Login
   * @param {string} email
   * @param {string} pwd
   * @return {number} status
   * @public
   */
  login(email: string, pwd: string) {
    const url = this._urlService.getUrl('login');

    return this.http
      .post(url, {
        username: email.trim(),
        password: pwd
      }, {
        headers: this.headers,
        observe: `body`,
        responseType: 'json'
      })
      .pipe(
        map((response: any) => {
          let status = 400;
          if (response) {
            const customer = response[0];
            const msg = response[1];
            status = response[2];
            if (status == 200) {
              this._userService.setUser(customer);
              this._userService.setFullname$.next(`${customer.firstname} ${customer.lastname}`);
              this._tokenService.setTypeToken('default');
              this._tokenService.setToken(customer.auth_token);
              this._apiService.isLogged$.next(true);
              this._autoLoginVsMagService.autoLoginSubject$.next({
                email,
                password: pwd
              });
            }
          }
          return status;
        }),
      );
  }

  /**
   * Login via user token
   * @param {string} token
   * @return {User} Return a object user and set islogged
   * @public
   */
  tokenLogin(token: string) {
    const url = this._urlService.getUrl('autoLogin');
    const headers = this.headers;

    return this.http
      .post(url, {
        token
      }, {
        headers,
        observe: `body`,
        responseType: 'text'
      })
      .pipe(
        map((response) => {
          if (!response) this.router.navigate(['/']);
          this._userService.setUser(JSON.parse(response));
          this._tokenService.setTypeToken('default');
          this._tokenService.setToken(token);
          this._userService.setFullname$.next(`${JSON.parse(response).firstname} ${JSON.parse(response).lastname}`);
          this._apiService.isLogged$.next(true);
        }),
        shareReplay(1),
        catchError((e) => {
          const err = JSON.parse(e.error);
          this._customCookieService.deleteCookie(['auth_token', "auth_token_sso"]);
          return throwError(() => new Error('Error Auth Login Token'));
        })
      );
  }

  validateTokenGoogleSocialLogin(token:string) {
    const url = `${GOOGLE_URL_VALIDATION_TOKEN}${token}`
    return this.http.get(url).pipe(
      map((res: any) => {
        if (!res) this.router.navigate(['/']);
        const { aud, exp, family_name, given_name, email}: GoogleTokenInfo = res;
        if(aud === GOOGLE_APP_ID && exp * 1000 > Date.now()) {
          const user = new User();
          user.authToken = token;
          user.email = email;
          user.firstname = family_name;
          user.lastname = given_name;

          this._userService.setUser(user); 
          this._tokenService.setTypeToken('google');
          this._tokenService.setToken(token);
          this._userService.setFullname$.next(`${family_name} ${given_name}`);
          this._apiService.isLogged$.next(true);
        }
        return res;
      }),
      shareReplay(1),
      catchError((e) => {
        const err = JSON.parse(e.error);
        this._customCookieService.deleteCookie(['auth_token', "auth_token_sso"]);
        return throwError(() => new Error('Error Auth Login Token'));
      })
    )
  }

  validateTokenFacebookLogin(token:string) {
    const appId = FACEBOOK_APP_ID;
    // const appSecret = 'YOUR_APP_SECRET';
    // const appAccessToken = `${appId}|${appSecret}`;
    const url = `${FACEBOOK_URL_VALIDATION_TOKEN}input_token=${token}&access_token=${appId}`
    return this.http.get(url).pipe(
      map((res: any) => {
        debugger;
        if (!res) this.router.navigate(['/']);

        if (res.data && res.data.is_valid && res.data.app_id === appId) {
          // TODO finire
          // - Ensure the token hasn’t expired (data.data.expires_at)
            
          /* const user = new User();
          user.authToken = token;
          user.email = email;
          user.firstname = family_name;
          user.lastname = given_name;

          this._userService.setUser(user); 
          this._tokenService.setTypeToken('google');
          this._tokenService.setToken(token);
          this._userService.setFullname$.next(`${family_name} ${given_name}`);
          this._apiService.isLogged$.next(true); */

          return res;
        }

        // exp * 1000 > Date.now()
        // if(aud === GOOGLE_APP_ID && exp * 1000 > Date.now()) {
      }),
      shareReplay(1),
      catchError((e) => {
        const err = JSON.parse(e.error);
        this._customCookieService.deleteCookie(['auth_token', "auth_token_sso"]);
        return throwError(() => new Error('Error Auth Login Token'));
      }));
  }
}
