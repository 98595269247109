import { Injectable } from '@angular/core';
import { forEach } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  setItem(key: string, value: any, ttl: number = 3600): void {
    const expiry = new Date().getTime() + ttl * 1000; // ttl is in seconds
    const data = { value, expiry };
    localStorage.setItem(key, JSON.stringify(data));
  }

  getItem(key: string): any {
    const item = localStorage.getItem(key);
    if (!item) {
      return null;
    }

    const data = JSON.parse(item);
    const now = new Date().getTime();

    if (now > data.expiry) {
      localStorage.removeItem(key);
      return null; // Item expired
    }

    return data.value;
  }

  removeItem(key: string | Array<string>): void {
    if (!key) return;
    const keys = (typeof key === 'string') ? [key] : key;
      
    keys.forEach(k => {
      localStorage.removeItem(k);    
    });
  }

  clear(): void {
    localStorage.clear();
  }
}

