import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class VersionService {
  private packageJsonPath = '/assets/package.json';

  constructor(private http: HttpClient) {}

  getVersion(): Observable<string> {
    return this.http.get<{ version: string }>(this.packageJsonPath).pipe(
      map((data) => data.version)
    );
  }

  getEnvironment() {
    return environment.production;
  }
}