import *  as assConfig  from './assistance.config';
import { ApiService } from '../service/api.service';
import { AssetImage } from 'src/entities/assetImage';
import { Assistance } from 'src/entities/assistance';
import { AssistanceService } from '../service/assistance.service';
import { BehaviorSubject, Observable, Subject, delay } from 'rxjs';
import { Component, Input, OnInit } from '@angular/core';
import { Detail } from 'src/entities/detail';
import { DeviceService } from '../service/device.service';
import { FileUploadService } from '../service/file-upload.service';
import { GtmService } from '../service/gtm.service';
import { Image } from 'src/entities/image';
import { Order } from 'src/entities/order';
import { RouteService } from '../service/route.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UtilityService } from '../service/utility.service';
import { WHATSAPP_URL } from 'src/config/social';
import{ ASSISTANCE } from 'src/config/images';

@Component({
  selector: 'app-assistance',
  templateUrl: './assistance.component.html',
  styleUrls: ['./assistance.component.scss']
})

export class AssistanceComponent implements OnInit {
  @Input() order!: Order;
  @Input() showButtonActions!: boolean;
  @Input() isCardProduct!: boolean;
  @Input() isSingleProduct: boolean = false;
  @Input() product?: Detail;

  assetImg:AssetImage = ASSISTANCE;
  assistance$: Observable<Assistance[]>;
  grandTotal$: Subject<number>;
  shippingCost$: Subject<number>;
  subtotal$: Subject<number>;

  currentFile?: File;
  isAfterOrder: boolean = false;
  loadedImages: Image[] = [];
  selLvlTwo: number = 0;
  selectedFiles?: FileList;
  showModal: boolean = false;
  showModalAssistanceDone: boolean = false;

  conditionAssistenceRequired: boolean = false;
  conditionSingleAssistenceRequired: boolean = false;
  disableAssFirstLvlProduct:boolean = false;

  showBtnAssistance: boolean = false;
  step:number = 1;
  selLvlOne: number =  assConfig.LVL_ONE_ASS_PRODOTTO; // default 1 per assegnare ad assistenza prodotto
  productSelected: number | null = null;
  selectedIdBuodes: number = 0;

  selLvOne$: BehaviorSubject<number> = new BehaviorSubject<number>(assConfig.LVL_ONE_ASS_PRODOTTO); // default 1 per assegnare ad assistenza prodotto
  selLvlTwo$: BehaviorSubject<number> = new BehaviorSubject<number>(0)

  itemsPerSlide = 3;
  singleSlideOffset = true;
  noWrap = true;

  isMobile: boolean = false;

  // lvl_one_ass_prodotto: number = assConfig.LVL_ONE_ASS_PRODOTTO;
  lvl_one_ass_ordine: number = assConfig.LVL_ONE_ASS_ORDINE;
  lvl_one_ass_consegna: number = assConfig.LVL_ONE_ASS_CONSEGNA;
  lvl_one_ass_altro: number = assConfig.LVL_ONE_ASS_ALTRO;
  lvl_two_prodotto_art_danneggiato: number = assConfig.LVL_TWO_PRODOTTO_ART_DANNEGGIATO;
  lvl_two_prodotto_art_mancanti: number = assConfig.LVL_TWO_PRODOTTO_ART_MANCANTI;
  lvl_two_prodotto_art_errato: number = assConfig.LVL_TWO_PRODOTTO_ART_ERRATO;
  lvl_two_prodotto_art_difettoso: number = assConfig.LVL_TWO_PRODOTTO_ART_DIFETTOSO;

  constructor(
    private _assistanceService: AssistanceService,
    private _gtmService: GtmService,
    private _routeService: RouteService,
    private _translate: TranslateService,
    private _uploadService: FileUploadService,
    private _utilityService: UtilityService,
    private router: Router,
    public _apiService: ApiService,
    public _detectorService: DeviceService
  ) {
    this.grandTotal$ = this._apiService.grandTotal$;
    this.shippingCost$ = this._apiService.shippingCost$;
    this.subtotal$ = this._apiService.subtotal$;
    this.assistance$ = this._assistanceService.assistance$;
    this.isMobile = this._detectorService._isMobile.getValue();
  }

  ngOnInit(): void {
    this.isAfterOrder = this._utilityService.isOrderAfterGoLive(this.order.dbu);
    const checkRev = this.order.rev === '0';
    const hasProductWithAssistance = this.hasProductWithAssistance(this.order)

    this.disableAssFirstLvlProduct = this.order.annullato || this.order.statoKey != 'consegnato' ||
    (this.order.catesterno != undefined && this.order.catesterno )||
    (this.order.isGeometra != undefined && this.order.isGeometra )||
    (this.order.isDolceCasa != undefined && this.order.isDolceCasa )||
    (this.order.fuorizona != undefined && this.order.fuorizona )||
    (this.order.isDropOff != undefined && this.order.isDropOff )||
    (this.order.isAddebitoTrasporto != undefined && this.order.isAddebitoTrasporto )||
    (this.order.isRiprogrammazione != undefined && this.order.isRiprogrammazione )||
    (this.order.isCostiGiacenza != undefined && this.order.isCostiGiacenza) ||
    this._utilityService.checkIsRevisione(this.order.rev);
    this.showBtnAssistance = this.order.annullato || (!hasProductWithAssistance && this.isAfterOrder); // && genericConditions
    this.conditionAssistenceRequired = checkRev && hasProductWithAssistance && this.isCardProduct;
    if(this.isSingleProduct && this.product) {
      this.conditionSingleAssistenceRequired = checkRev && this.product.assflag;
    }
  }

  sendRequest() {
    // const idbuodes = (this.product instanceof Array) ? this.product[0].idbuodes : this.product.idbuodes;
    const idbc = `${this.order.prbu}${this.order.rev}`;
    this._assistanceService.cbkAssistance({
      "guid": this.order.guid,
      "idbc": idbc,
      "idbuodes": this.selectedIdBuodes,
      "messaggio": this.fnConvertLvlTwo(),
      "cidra": this.selLvlTwo$.getValue()
    }).subscribe((response) => {
      if (response && response.length > 0) {
        const id = response[0].id;
        if (this.loadedImages && this.loadedImages.length > 0)
          this.loadedImages.map((image) => {
            const imgContentArray = image.content.split(',');
            this._uploadService.upload([{
              id: `${id}`,
              idbc,
              nomefile: image.nomefile,
              content: imgContentArray[1]
            }]).pipe(
              delay(1000)
            ).subscribe();
          });
        this.closeModal();
        this.changeShowModalAssistanceDone();
        this._gtmService.eventAssistance(this.fnConvertLvlOne(), this.fnConvertLvlTwo());
        if (this._apiService.isLogged$.getValue()) {
          this._apiService.isLogged$.next(true);
        }
        if (this._apiService.isGuest$.getValue()) {
          this._apiService.refreshCbkGuest();
        }
      }
    })
  }

  selectFile(event: any): void {
    let sum:number = 0
    let preview: string = '';

    this.selectedFiles = event.target.files;

    if (this.selectedFiles) {
      for (let i = 0; i < this.selectedFiles.length; i++) {
        sum += this.selectedFiles.item(i)?.size || 0;
      }
    }
    if (sum / 1024 / 1024 > 10) {
      alert(this._translate.instant('assistance.alert'));
    } else if (this.selectedFiles) {
      for (let i = 0; i < this.selectedFiles.length; i++) {
        const file: File | null = this.selectedFiles.item(i);

        if (file) {
          preview = '';
          this.currentFile = file;
          const reader = new FileReader();

          reader.onload = (e: any) => {
            preview = e.target.result;

            this.loadedImages.push({
              'content': preview,
              'nomefile': file.name
            });
          };

          reader.readAsDataURL(this.currentFile);
        }
      }
    }
  }

  removeSelectedFile(index: number) {
    this.loadedImages.splice(index, 1);
  }

  clickUpload() {
    let element: HTMLElement = document.getElementById(
      'auto-trigger'
    ) as HTMLElement;

    element.click();
  }

  changeShowModalAssistanceDone() {
    this.showModalAssistanceDone = !this.showModalAssistanceDone;
  }

  changeShowModal() {
    this.showModal = !this.showModal;
  }

  closeModal() {
    this.step = 1;
    this.productSelected = null;
    this.selLvlOne = 0;
    this.selLvlTwo = 0;
    this.showModal = false;
    this.showModalAssistanceDone = false;
    this.selectedIdBuodes = 0;
  }

  previousStep() {
    if (this.step === 5) {
      this.step = 1; return;
    }

    this.step = this.step - 1

    return this.step;
  }

  nextStep(val: number | null = null) {
    this.step = (val == null) ? this.step + 1 : this.step = val;
  }

  updateValueLvlOne(val:number) {
    this.selLvOne$.next(val);
    this.selLvlOne = val;
  }

  updateValueLvlTwo(n: number) {
    this.selLvlTwo$.next(n);
    this.selLvlTwo = n;
    this.nextStep()
  }

  updateProduct(selected: number, product: Detail) {
    this.productSelected = selected;
    this.selectedIdBuodes = product.idbuodes;
  }

  goToWa() {
    window.open(WHATSAPP_URL, '_blank');
  }

  goToChat() {
    const idbc = `${this.order.prbu}${this.order.rev}`;
    this._gtmService.eventAssistance(this.fnConvertLvlOne(), this.fnConvertLvlTwo());
    this._utilityService.openChat(idbc);
  }

  goToOrders() {
    this._routeService.goTo('orders');
    this.closeModal();
  }

  goToSignUp() {
    this._routeService.goTo('signup');
  }

  private fnConvertLvlTwo() {
    const val = this.selLvlTwo$.getValue();
    switch (val) {
      case assConfig.LVL_TWO_PRODOTTO_ART_DANNEGGIATO:
        return "Articolo danneggiato";
      case assConfig.LVL_TWO_PRODOTTO_ART_MANCANTI:
        return "Componenti o accessori mancanti"
      case assConfig.LVL_TWO_PRODOTTO_ART_ERRATO:
        return "Ricevuto articolo errato"
      case assConfig.LVL_TWO_PRODOTTO_ART_DIFETTOSO:
        return "Articolo difettoso o non funzionante"
      default:
        return '';
    }
  }

  private fnConvertLvlOne() {
    const val = this.selLvOne$.getValue();
    switch (val) {
      case assConfig.LVL_ONE_ASS_PRODOTTO:
        return "Assistenza sul prodotto";
      case assConfig.LVL_ONE_ASS_ORDINE:
        return "Assistenza su ordine"
      case assConfig.LVL_ONE_ASS_CONSEGNA:
        return "Assistenza sulla consegna"
      case assConfig.LVL_ONE_ASS_ALTRO:
        return "Altro tipo di assistenza"
      default:
        return '';
    }
  }

  private hasProductWithAssistance (order: Order): boolean {
    const cleanObj = order.dettagli.filter((detail:Detail) => !detail.trasporto);
    return cleanObj.every((detail: Detail) => {
      return detail.assflag;
    });
  }

}
