<div class="form-container">
  <div class="logo-section">
    <img src="{{ logoUrl | translate }}" alt="Mondo Convenienza Logo" class="mb-md-5 mb-sm-4 mx-auto d-block" />
  </div>
  <div class="welcome-section">
    <p>{{ 'waitingList.form.title' | translate }}</p>
    <span class="subtitle">{{ 'waitingList.form.subtitle' | translate }}</span>
  </div>

  <form [formGroup]="formData">
    <div class="form-group">
      <label for="firstname">{{ "commons.txtFirstname" | translate | uppercase  }}*</label>
      <input type="text" id="firstname" name="firstname" formControlName="firstname"
        placeholder="{{ 'commons.txtFirstname' | translate }}" required />
      <small class="text-danger" *ngIf="formData.get('firstname')?.invalid && formData.get('firstname')?.touched">
        {{ getErrorMessage('firstname') }}
      </small>
    </div>

    <div class="form-group">
      <label for="lastname">{{ "commons.txtLastname" | translate | uppercase  }}*</label>
      <input type="text" id="lastname" name="lastname" formControlName="lastname"
        placeholder="{{ 'commons.txtLastname' | translate }}" required />
      <small class="text-danger" *ngIf="formData.get('lastname')?.invalid && formData.get('lastname')?.touched">
        {{ getErrorMessage('lastname') }}
      </small>
    </div>

    <div class="form-group">
      <label for="phone">{{ "commons.txtPhone" | translate | uppercase  }}</label>
      <input type="tel" id="phone" name="phone" formControlName="phone"
        placeholder="{{ 'commons.txtPhone' | translate }}" />
    </div>

    <div class="form-group">
      <label for="email">{{ "personalData.txtEmail" | translate | uppercase  }}*</label>
      <input type="email" id="email" name="email" formControlName="email"
        placeholder="{{ 'personalData.txtEmail' | translate }}" />
      <small class="text-danger" *ngIf="formData.get('email')?.invalid && formData.get('email')?.touched">
        {{ getErrorMessage('email') }}
      </small>
    </div>

    <div class="form-group">
      <label for="store">{{"waitingList.form.meetingPoint" | translate | uppercase  }}</label>
      <input 
        type="text" 
        id="department" 
        name="department" 
        formControlName="department"
        placeholder='{{"waitingList.form.meetingPoint" | translate }}' />
    </div>

    <div class="form-group">
      <label for="shop">{{ "commons.txtAddressStore" | translate | uppercase  }}</label>
      <input 
        type="text" 
        id="shop" 
        name="shop" 
        formControlName="shop"
        placeholder='{{ "commons.txtAddressStore" | translate }}'/>
    </div>

    <div class="form-group" *ngIf="isGuest">
      <label for="password">{{ "personalData.txtPwd" | translate | uppercase  }}</label>
      <div class="mc-password-field input-group mx-0 mb-md-4 mb-sm-3">  
        <input class="form-control" id="password" [type]="show_button[0] ? 'text' : 'password'" name="password"
          placeholder="Password" 
          formControlName="password"/>
        <i class="input-group-text" [class]="show_eye[0] ? 'bi bi-eye' : 'bi bi-eye-slash'" (click)="showPassword(0)"></i>
        <small class="text-danger" *ngIf="formData.get('password')?.invalid && formData.get('password')?.touched">
          {{ getErrorMessage('password') }}
        </small>
      </div>
    </div>
    <div class="form-group checkbox" *ngIf="isGuest">
      <input type="checkbox" id="privacy" name="privacy" formControlName="privacy"/>
      <label for="privacy">{{"waitingList.form.privacyInformation" | translate }}</label>
    </div>
    <div class="form-group checkbox">
      <input type="checkbox" id="newsletter" name="newsletter" formControlName="newsletter"/>
      <label for="newsletter">{{"waitingList.form.communicationsInformation" | translate }}</label>
    </div>
    <button 
      class="btn btn-primary w-100 mb-0" 
      [disabled]="formData.invalid"
      (click)="onSubmit()">
      {{"waitingList.form.button" | translate }}
    </button>
  </form>
</div>