import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UrlService {
  constructor(
  ) {}

  getUrl(code:string): string {
    const { middlewareIt, middlewareDirect, apiPaths } = environment;

    switch (code) {
      case 'loginAdmin':
        return `${middlewareIt}${apiPaths.loginAdmin}`;
      case 'orderByGuid':
        return `${middlewareIt}${apiPaths.orderByGuid}`;
      case 'order':
        return `${middlewareIt}${apiPaths.order}`;
      case 'login':
        return `${middlewareIt}${apiPaths.login}`;
      case 'autoLogin':
        return `${middlewareIt}${apiPaths.autoLogin}`;
      case 'appointment':
        return `${middlewareIt}${apiPaths.appointment}`;
      case 'requestAssistance':
        return `${middlewareIt}${apiPaths.requestAssistance}`;
      case 'autoLoginVsMagento':
        return `${middlewareIt}${apiPaths.autoLoginVsMagento}`;
      case 'uploadAssistanceFiles':
        return `${middlewareIt}${apiPaths.uploadAssistanceFiles}`;
      case 'invoice':
        return `${middlewareIt}${apiPaths.invoice}`;
      case 'getNewsletter':
        return `${middlewareIt}${apiPaths.getNewsletter}`;
      case 'subscribe':
        return `${middlewareIt}${apiPaths.subscribe}`;
      case 'unsubscribe':
        return `${middlewareIt}${apiPaths.unsubscribe}`;
      case 'startUserPayment':
        return `${middlewareIt}${apiPaths.startUserPayment}`;
      case 'sendEmailPbl':
          return `${middlewareIt}${apiPaths.sendEmailPbl}`;
      case 'signup':
        return `${middlewareIt}${apiPaths.signup}`;
      case 'editAccount':
        return `${middlewareIt}${apiPaths.editAccount}`;
      case 'deleteAccount':
          return `${middlewareIt}${apiPaths.deleteAccount}`;
      case 'waitlistInsert':
            return `${middlewareDirect}${apiPaths.waitlist.insert}`;
      case 'waitlistStatus':
            return `${middlewareDirect}${apiPaths.waitlist.status}`;
      default:
        return ''
    }
  }
}
