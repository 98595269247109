<div *ngIf="orders$ | async as orders" [hidden]="isBannerHidden">
  <div *ngIf="orders && orders.length > 0" class="notification-banner">
    <div class="icon-container">
      <img [src]="assetImgMail" alt="Mail Icon" />
    </div>
    <div class="content-container">
      <p class="title"> {{ "reservation.title" | translate | uppercase }}</p>
      <p class="message">
        {{ "reservation.txtRememberReservations" | translate: {
        fullname: fullname | async,
        }
        }}
        <strong *ngFor="let order of orders; let last = last">
          <span class="cursor-point" (click)="copyText(order)">{{ order }}</span>{{ !last ? ', ' : '.' }}
        </strong>
      </p>
    </div>
    <button class="close-btn" (click)="hideBanner()">×</button>
  </div>
</div>