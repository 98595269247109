import { CodOperatoreService } from 'src/app/service/codOperatore.service';
import { Component, ComponentRef, EventEmitter, Input, Output, ViewContainerRef } from '@angular/core';
import { concat } from 'rxjs';
import { DeviceService } from 'src/app/service/device.service';
import { GtmService } from 'src/app/service/gtm.service';
import { Order } from 'src/entities/order';
import { PayByLinkService } from 'src/app/service/modal/payByLink.service';
import { Payment } from 'src/entities/Payment';
import { PaymentEmail } from 'src/entities/PaymentEmail';
import { PaymentRes } from 'src/entities/PaymentRes';
import { PblInfo } from 'src/entities/PblInfo';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { PayByLinkAdminService } from 'src/app/service/modal/payByLinkAdmin.service';

@Component({
  selector: 'app-pay-by-link',
  templateUrl: './pay-by-link.component.html',
  styleUrls: ['./pay-by-link.component.scss'],
})
export class PayByLinkComponent {
  @Input() order!:any;
  @Input() isAdmin:boolean = false;
  @Output() closeModal = new EventEmitter<any>();

  isMobile: boolean = false;
  selectedBox: string = '';
  payByLinkInfoModal: PblInfo = new PblInfo();

  constructor(
    private _codOperatore: CodOperatoreService,
    private _deviceService: DeviceService,
    private _gtmService: GtmService,
    private _payByLinkService: PayByLinkService,
    private _translateService: TranslateService,
    private _payByLinkAdminService: PayByLinkAdminService,
    private router: Router,
    private viewContainerRef: ViewContainerRef
  )  {
    this.isMobile = this._deviceService._isMobile.getValue();
  }

  ngOnInit() {
  }

  close(event: any) {
    this.closeModal.emit(event);
  }

  selectBox(n: string) {
    this.selectedBox = n;
  }

  sendRequest() {
    this._gtmService.eventPayByLink();
    const params = new Payment;

    params.email = (Array.isArray(this.order)) ? this.order[0].email : this.order.email;
    params.pagamento = this.selectedBox.toUpperCase();
    params.ordini = this.objOrdersPbl(this.order);

    this._payByLinkService.payByLink(params).subscribe((response: PaymentRes) => {
      if (!response) throw new Error("Not response from api");
      if (response.resultCode == 'IGFS_000') {
        window.open(response.url, '_blank');
      }
    });
  }

  sendRequestAdmin () {
    this._gtmService.eventPayByLink(true);
    const params: PaymentEmail = new PaymentEmail;
    const paramsP = new Payment;
    const ordini = this.objOrdersPbl(this.order);
    const selPayment = this.selectedBox.toUpperCase();

    paramsP.email = this.order[0].emailP;
    paramsP.pagamento = selPayment;
    paramsP.ordini = ordini;

    params.pagamento = selPayment;
    params.ordini = ordini;
    params.codmcpv = this.order[0].codmcpv;
    params.guidcli = this.order[0].guid;
    params.intestatario = `${this.order[0].nom} ${this.order[0].cog}`;
    params.email = this.order[0].emailP
    params.cusr = this._codOperatore.getCode();

    const obsPbl$ = this._payByLinkService.payByLink(paramsP);
    const obsPblEmail$ = this._payByLinkService.sendEmailPbn(params);
    concat(obsPbl$, obsPblEmail$).subscribe((response) => {
      if (this.isAdmin && 'transPosId' in (response)) {
        if(this.order.length > 0) {
          this.payByLinkInfoModal = {
            link: response.url,
            exp: response.expire,
            payment: this.selectedBox.toUpperCase(),
            email: this.order[0].emailP,
            sms: ''
          };
          // this.close(new MouseEvent('click'));
          this.openModalPayByLinkAdmin(this.payByLinkInfoModal)
        }
      }
    });
  }

  goToOrders () {
    this.router.navigate(['/orders']);
  };

  private objOrdersPbl (order: any) {
    const ordini: any = [];

    order.map((e: Order) => {
      const totale = Number((e.totale).toFixed(2));
      const saldo = Number((e.saldo).toFixed(2));
      const importo_pagato = Number((totale - saldo).toFixed(2));

      ordini.push({
        "idbc": e.prbu+e.rev,
        "importo_pagato": importo_pagato,
        "importo_da_pagare": saldo,
        "importo_bc": totale,
        "dbu": e.dbu,
        "dcci": e.dcci,
        "impgeo": 0 // sempre a 0
      });
    });

    return ordini;
  }

  openModalPayByLinkAdmin(payByLinkInfoModal: PblInfo) {
    this._payByLinkAdminService.setRootViewContainerRef(this.viewContainerRef);
    this._payByLinkAdminService.addDynamicComponent(payByLinkInfoModal)
  }
}
