import { ApiService } from '../service/api.service';
import { CodeControlComponent } from '../modal/code-control/code-control.component';
import { Component, EventEmitter, Output, ViewContainerRef } from '@angular/core';
import { DeviceService } from '../service/device.service';
import { DynamicModalService } from '../service/modal/dynamicModal.service';
import { GtmService } from '../service/gtm.service';
import { GuestService } from '../service/guest.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { RouteService } from '../service/route.service';
import { Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { WrongGuidComponent } from '../modal/wrong-guid/wrong-guid.component';
import { map, Subscription, switchMap, tap } from 'rxjs';

@Component({
  selector: 'app-code-guest-box',
  templateUrl: './code-guest-box.component.html',
  styleUrls: ['./code-guest-box.component.scss'],
})
export class CodeGuestBoxComponent extends DynamicModalService<CodeControlComponent | WrongGuidComponent> {
  guestBoxForm!: UntypedFormGroup;
  isMobile: boolean = false;
  apiServiceGuestSub$: Subscription = Subscription.EMPTY;

  @Output() changeLoginBox = new EventEmitter<boolean>();

  constructor(
    private _apiService: ApiService,
    private _fb: UntypedFormBuilder,
    private _gtmService: GtmService,
    private _guestService: GuestService,
    private _routeService: RouteService,
    private recaptchaV3Service: ReCaptchaV3Service,
    private route: Router,
    private viewContainerRef: ViewContainerRef,
    public _detectorService: DeviceService,
  ) {
    super();
    this.initForm();

    this.guestBoxForm.controls['controlCode'].valueChanges
    .subscribe(x=>{
      if(x && x.includes(' ')){
        this.guestBoxForm.controls['controlCode'].setValue(x.trim())
      }
    });

    this.isMobile = this._detectorService._isMobile.getValue();

  }

  ngOnInit() {}

  onSubmit() {
    if (this.guestBoxForm.valid) {
      let res:number = 0;
      this._guestService.setGuid(this.guestBoxForm.value.controlCode);
        this.apiServiceGuestSub$ = this._apiService.guestObs$.pipe(
        switchMap((response: number) => {
          res = response;
          return this.recaptchaV3Service.execute('login');
        }),
        map((token: string) => {
          if (!token) this.route.navigate(['/login']);
          return res;
        }),
        tap((response:number) => {
          if (response === 200) {
            this._gtmService.eventLoginGuid();
            if(window.location.pathname.includes('login')) {
              this.guestBoxForm.controls['controlCode'].setErrors({'incorrect': true});
              this.route.navigate(['/orders'])
            };
          } else {
            this.unsubscribe();
            this.openModalGuestBox(new MouseEvent('click'))
          }
        })
      ).subscribe();
    }
  }

  unsubscribe(): void {
    if (this.apiServiceGuestSub$) {
      this.apiServiceGuestSub$.unsubscribe();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe();
  }

  initForm() {
    this.guestBoxForm = this._fb.group({
      controlCode: ['', [Validators.required, Validators.pattern("^[A-Z0-9]*$")]],
    });
  }
  setLoginBox(value: boolean) {
    this.changeLoginBox.emit(value);
  }
  goToSignUp() {
    this._routeService.goTo('signup');
  }

  openModalGuestBox(e: Event, useComponent:boolean = false) {
    e.preventDefault();

    const componentToUse = (useComponent) ? CodeControlComponent : WrongGuidComponent;

    this.setRootViewContainerRef(this.viewContainerRef);

    const componentRef = this.addDynamicComponent(componentToUse);

    componentRef.instance.closeModal.subscribe(() => {
        this.removeDynamicComponent(componentRef);
    });
  }
}
