import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, finalize, Observable, throwError } from "rxjs";
import { LoadingService } from "./service/loading.service";

@Injectable()
export class NetworkInterceptor implements HttpInterceptor {
  constructor(private loader: LoadingService) { }

    /**
   * Interceptor HttpRequest
   * Start autoload and intercept error httprequest
   * @param { request, next}
   * @return { Error } Return an observable with a user-facing error message.
   */
  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (request.url.includes('api/auth/magentoRegistration')) return next.handle(request);

    if (request.url.indexOf('api/ordermagw/info') === -1) {
      this.loader.show();
      return next.handle(request).pipe(
        finalize(() => this.loader.hide()),
        catchError((error: HttpErrorResponse) => {
          if (request.url.includes('api/auth/magentoLogin')) return next.handle(request);

          if (request.url.includes('api/auth/adminLogin')) {
            if (error.status === 412) {
              return throwError(() => error);
            }
          }

          if (error.status === 500 || error.status === 400){
            return throwError(() => error);
          }

          let errorMsg = `This is server side error - Error Code: ${error.status},  Message: ${error.message}`;
          if (error.error instanceof ErrorEvent) errorMsg = `This is client side error - Error: ${error.error.message}`;
          return throwError(() => new Error('Something bad happened; please try again later.'));
        })
      );
    }
    if (request.url.indexOf('api/ordermagw/info') !== -1) this.loader.hide();
    return next.handle(request);
  }
}
