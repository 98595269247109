import {
  ACCEPTED_STATE,
  CANALE,
  CONSEGNA_GOLD,
  CONSEGNA,
  REG_PADRE,
  REG_REVISONE,
  REG_SDOPPIATO,
  STATE
} from "src/config/constats";
import { CustomCookieService } from "./customCookie.service";
import { Detail } from "src/entities/detail";
import { environment } from "src/environments/environment";
import { Injectable } from "@angular/core";
import { Order } from "src/entities/order";
import { Params } from "@angular/router";
import { SocialAuthService } from "@abacritt/angularx-social-login";

interface GetShowAppointmentInput {
  order: Order,
  isBoxOrder?: boolean;
  isGuest?: boolean;
  useCondition?: boolean;
}

@Injectable({
  providedIn: 'root'
})

export class UtilityService {
  baseUrlImg = `${environment.middlewareIt}${environment.apiPaths.img}`;
  isGuest: boolean = false;

  constructor(
    private _authService: SocialAuthService,
    private _customCookieService: CustomCookieService
  ) {}

  /**
   * function to convert consegna string
   * @param {string} consegna
   * @return {string}
   * @public
   */
  getConsegna(k: string) {
    return CONSEGNA[k];
  }

  /**
   * function to convert canale string
   * @param {string} canale
   * @return {string}
   * @public
   */
  getCanale(k: string, ks: string) {
    if (k == 'T' && ks != '') {
      if (ks.toLowerCase() == 'televendita') return 'Ordine telefonico';
      if (ks.toLowerCase() == 'store') return 'Punto vendita';
      return ks;
    }

    if (k == 'N' && ks != '') {
      if (ks.toLowerCase() == 'store') return 'Punto vendita';
      return ks;
    }

    return (ks != '') ? `${CANALE[k]} - ${ks}` : CANALE[k];
  }

  /**
   * function to convert canale string
   * @param {string} canale
   * @return {string}
   * @public
   */

  /*
  - Trasporto e Montaggio ‘P’
  - Ritiro Cliente ‘R’
  - Corriere Nazionale ‘C’
  - Sotto Casa Tua ‘S’
   */
  getStato(param: {
    k: string,
    consegna: string,
    perapp: boolean,
    dccorr: string | null,
    dcc: string | null,
    dapp: string | null,
    dcci: string | null,
    fasciacom: string | null,
    cfgold: number
  }) {
    const {
      k,
      consegna,
      dccorr,
      perapp,
      dcc,
      dapp,
      dcci,
      fasciacom,
      cfgold
    } = param;

    if (k === 'consegnato' && consegna == 'R') return "ritirato";

    if (k === 'consegna' && consegna == 'C' && dccorr != null) return "ritirato corriere";
    if (k === 'consegna' && consegna == 'C') return "in lavorazione";
    if (k === 'consegna') {
      const fasciaComm = (cfgold > 0) ? CONSEGNA_GOLD[cfgold] : fasciacom;

      let dateToUse = '';
      if (dcci != null) dateToUse = dcci;
      if (dapp != null) dateToUse = dapp;

      return `In consegna il ${dateToUse.replaceAll('/', '-')} nella fascia oraria ${fasciaComm}`;
    }

    if (k === 'appuntamento' && consegna == 'C' && dccorr != null) return "in lavorazione"; // se la se now < dccorr
    if (k === 'appuntamento' && consegna == 'C' && dccorr != null) return "ritirato corriere";
    if (k === 'appuntamento' && dapp != null) return `appuntamento il ${dapp.replaceAll('/', '-')}`;
    if (k === 'appuntamento' && consegna == 'R') return "pronto per l'appuntamento in magazzino";

    if (k === 'pronto' && consegna == 'R') return "pronto per l'appuntamento al ritiro";
    if (k === 'pronto' && (!perapp || consegna == 'C')) return "in lavorazione";

    return STATE[k];
  }

  getOrderDetailByParams(params: Params, orders: Array<Order>) {
    const rev = params.orderId.slice(-1);
    const prbu = params.orderId.substring(0, params.orderId.length - 1);

    const order = orders.find((el: Order) => {
      return el.prbu === prbu && el.rev === rev;
    })

    return order;
  }

  /**
  * Reformat iso date to localeDatestring
  * @param isoDate
  * @return date
  * @private
  */
  dateConverter(isoDate: string | number | Date | null) {
    if (!isoDate) return null;
    const d = new Date(isoDate);
    return d.toLocaleDateString('it-IT');
  }

  isOrderAfterGoLive(orderDbu: string): boolean {
    if (!orderDbu) return false;

    const dateOrder = new Date(orderDbu);
    const orderDate = new Date(dateOrder.getFullYear(), dateOrder.getMonth(), dateOrder.getDate());
    const goLiveDate = new Date(2023, 5, 21);
    return orderDate.getTime() >= goLiveDate.getTime();
  }

  exitMenu(): void {
    this._customCookieService.deleteCookie([
      'auth_token',
      'auth_token_sso',
      'customer',
      'user_firstname',
      'user_lasttname'
    ]);

    this._customCookieService.setCookie([{
      key: 'controlCookie',
      value: "false"
    }]);

    this._authService.signOut();
    window.location.reload();
  }

  checkIsRevisione(rev:string): boolean {
    return REG_REVISONE.test(rev);
  }

  checkIsSdoppiato(rev:string): boolean {
    return REG_SDOPPIATO.test(rev);
  }

  checkIsParteOrdine(rev:string): boolean{
    return REG_PADRE.test(rev);
  }

  /**
   * UNUSED
   * @param order
   * @returns string
   */
  getUrlCoordinates(order: Order) {
    const { latitudePv, longitudePv, latitudeDep, longitudeDep } = order;

    const latitude = latitudeDep || latitudePv;
    const longitude = longitudeDep || longitudePv;

    if (!latitude || !longitude) return ''; // aggiugnere default

    return `https://www.google.com/maps/search/?api=1&query=${latitude}%2C${longitude}`;
  }

  getShowEditAppointment(
    {
    order,
    isBoxOrder = false,
    isGuest = false
  }:GetShowAppointmentInput
  ): boolean {
    const {
      statoKey,
      perapp,
      annullato,
      catesterno,
      dateutili,
      isCatanzaroDep,
      blockReservation,
      nazione
    } = order;
      const isNazioneIta = isGuest ? true : nazione === 'ITA';
      return statoKey == ACCEPTED_STATE[8] && perapp && dateutili != '' && !annullato && !catesterno && isBoxOrder && !isCatanzaroDep && !blockReservation && isNazioneIta;
  }

  // remove isREvisione
  getShowCreateAppointment(
    {
      order, 
      isBoxOrder = false,
      isGuest = false,
      useCondition = false
    }:GetShowAppointmentInput
  ) : boolean {
      const  { 
        statoKey,
        perapp,
        annullato,
        catesterno,
        isCatanzaroDep,
        nazione,
        blockReservation,
        idraulico
      }=order;
      const acceptedStateAppointment = (statoKey == ACCEPTED_STATE[9] || statoKey == ACCEPTED_STATE[4]);
      const isNazioneIta = isGuest ? true : nazione === 'ITA';
      const baseCondition:boolean = acceptedStateAppointment && perapp && !annullato && !catesterno && isBoxOrder && !isCatanzaroDep && isNazioneIta && !idraulico;
      return  useCondition ? (baseCondition && !blockReservation) : baseCondition;
  }

  assignImageToProduct(detail: Detail, order: Order): string {
    const pathImgServizio = '/assets/img/';

    if (order.isRiprogrammazione) return `${pathImgServizio}riprogrammazione.png`;
    if (order.isGeometra) return `${pathImgServizio}geometra.png`;
    if (order.isDolceCasa) return `${pathImgServizio}dolcecasa.png`;
    if (order.isCostiGiacenza) return `${pathImgServizio}giacenza.png`;
    if (order.isAddebitoTrasporto) return `${pathImgServizio}addebito.png`;
    if (order.isTraghetto)  return `${pathImgServizio}traghetto.png`;
    if (order.fuorizona) return `${pathImgServizio}fuorizona.png`;

    return (detail.hasImage) ? `${this.baseUrlImg}${detail.sid}?width=200` : `${pathImgServizio}placeholder.png`;
  }

  getPadStart(v: string | number, position: number) {
    return v.toString().padStart(position, '0');
  }

  openChat(orderId?: string) {
    const buttonToClick = document.getElementsByClassName('embeddedMessagingConversationButton')[0] as HTMLElement;
    if (typeof window.updateCC === 'function' && orderId) {
      window.updateCC(orderId);
    }
    buttonToClick.click();
  }

  setAddress(values: any) {
    return values.indlib !== ''
    ? values.indlib
    : [
        values.ind || '',
        `, ${values.civico}` || ''
      ].filter(part => part !== '').join('');
  }

}
