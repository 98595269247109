import { ApiService } from "../service/api.service";
import { Component } from '@angular/core';
import { CustomCookieService } from "../service/customCookie.service";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { first } from "rxjs";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'app-banner-bottom',
  templateUrl: './banner-bottom.component.html',
  styleUrls: ['./banner-bottom.component.scss'],
})
export class BannerBottomComponent {
  isBannerHidden:boolean = false;
  safeHtml!: SafeHtml;

  constructor(
    private _customCookieService: CustomCookieService,
    private _translate: TranslateService,
    private router: Router,
    private sanitizer: DomSanitizer,
    public _apiService: ApiService,
  ) {
    this._translate.get('banner.message').pipe(first()).subscribe((res: string) => {
      this.safeHtml = this.sanitizer.bypassSecurityTrustHtml(res);
    });

  }

  onActionClick(evt: MouseEvent) {
    const target = evt.target as Element;
    if (target && target.classList.contains('bnr-action')) {
      evt.preventDefault();
      this._apiService.isLogged$.next(false);
      this._customCookieService.deleteCookie(['auth_token', 'auth_token_sso']);
      this.router.navigate(['/login'], {queryParams: {mode: 'guest'}});
    }

    if (target && target.classList.contains('bnr-action-service')) {
      evt.preventDefault();
      const buttonToClick = document.getElementsByClassName('embeddedMessagingConversationButton')[0] as HTMLElement;
      buttonToClick.click();
    }
  }

  hideBanner() {
    this.isBannerHidden = true;
  }
}
