import { AssetImage } from "src/entities/assetImage";

export const PATH_ASSETS:string = '/assets/img/';

const PATH_ASSETS_ASSISTANCE:string = 'assistance/';
const PATH_ASSETS_PAYMENT:string = 'payment/';
const PATH_ASSETS_RESERVATION:string = 'reservation/';
const PATH_ASSETS_PBL:string = 'payByLink/';
const PATH_ASSETS_ORDER_DELETED:string = 'orderDeleted/';
const PATH_ASSETS_PERSONAL_AREA:string = 'areaPersonale/';
const PATH_ASSETS_MENU:string = 'iconeMenu/'
const PATH_ASSETS_WAITING_LIST:string = 'waiting-list/'

export const ASSISTANCE: AssetImage = {
    prodotto: `${PATH_ASSETS}${PATH_ASSETS_ASSISTANCE}prodotto.svg`,
    ordine: `${PATH_ASSETS}${PATH_ASSETS_ASSISTANCE}ordine.svg`,
    consegna: `${PATH_ASSETS}${PATH_ASSETS_ASSISTANCE}consegna.svg`,
    altrotipo: `${PATH_ASSETS}${PATH_ASSETS_ASSISTANCE}altrotipo.svg`,
    rotto: `${PATH_ASSETS}${PATH_ASSETS_ASSISTANCE}rotto.svg`,
    mancante: `${PATH_ASSETS}${PATH_ASSETS_ASSISTANCE}mancante.svg`,
    errato: `${PATH_ASSETS}${PATH_ASSETS_ASSISTANCE}errato.svg`,
    difettoso: `${PATH_ASSETS}${PATH_ASSETS_ASSISTANCE}difettoso.svg`,
    chat: `${PATH_ASSETS}${PATH_ASSETS_ASSISTANCE}chat.png`,
    success: `${PATH_ASSETS}${PATH_ASSETS_ASSISTANCE}success.svg`,
    wa: `${PATH_ASSETS}${PATH_ASSETS_ASSISTANCE}wa.png`,
    chatJpg: `${PATH_ASSETS}${PATH_ASSETS_ASSISTANCE}chat.jpg`,
  };

  export const PAYMENT: AssetImage = {
    visa: `${PATH_ASSETS}${PATH_ASSETS_PAYMENT}visa.png`,
    findomestic: `${PATH_ASSETS}${PATH_ASSETS_PAYMENT}findomestic.png`,
    bonificoGen: `${PATH_ASSETS}${PATH_ASSETS_PAYMENT}bonifico-gen.png`,
    bonifico: `${PATH_ASSETS}${PATH_ASSETS_PAYMENT}bonifico.png`,
    paypal: `${PATH_ASSETS}${PATH_ASSETS_PAYMENT}paypal.png`,
    crypto: `${PATH_ASSETS}${PATH_ASSETS_PAYMENT}cryptovalute.png`,
    consegna: `${PATH_ASSETS}${PATH_ASSETS_PAYMENT}consegna-pag.png`,
    assegno: `${PATH_ASSETS}${PATH_ASSETS_PAYMENT}assegno.png`,
  };
  

  export const RESERVATION: AssetImage = {
    mail: `${PATH_ASSETS}${PATH_ASSETS_RESERVATION}mail.svg`,
    ambienti: `${PATH_ASSETS}${PATH_ASSETS_RESERVATION}ambienti.svg`,
    preseelettriche: `${PATH_ASSETS}${PATH_ASSETS_RESERVATION}preseelettriche.svg`,
    pacco: `${PATH_ASSETS}${PATH_ASSETS_RESERVATION}pacco.svg`,
    consegna: `${PATH_ASSETS}${PATH_ASSETS_RESERVATION}consegna.png`,
    fattura: `${PATH_ASSETS}${PATH_ASSETS_RESERVATION}fattura.png`,
    doc: `${PATH_ASSETS}${PATH_ASSETS_RESERVATION}doc.png`,
    checkSquare: `${PATH_ASSETS}${PATH_ASSETS_RESERVATION}check-square.svg`,
  };

  export const ORDER_DELETED: AssetImage = {
    icon: `${PATH_ASSETS}${PATH_ASSETS_ORDER_DELETED}icon.svg`,
    phone: `${PATH_ASSETS}${PATH_ASSETS_ORDER_DELETED}phone.png`,
    arrow: `${PATH_ASSETS}${PATH_ASSETS_ORDER_DELETED}arrow.svg`,
    chat: `${PATH_ASSETS}${PATH_ASSETS_ORDER_DELETED}chat.png`,
    wa: `${PATH_ASSETS}${PATH_ASSETS_ORDER_DELETED}wa.png`,
    store: `${PATH_ASSETS}${PATH_ASSETS_ORDER_DELETED}store.png`,
  };

  export const PBL: AssetImage = {
    bonifico: `${PATH_ASSETS}${PATH_ASSETS_PBL}bonificobanc.png`,
    carta: `${PATH_ASSETS}${PATH_ASSETS_PBL}cartedicredito.png`,
  };

  export const PERSONAL_AREA: AssetImage = {
    editIcon: `${PATH_ASSETS}${PATH_ASSETS_PERSONAL_AREA}editIcon.svg`,
    address: `${PATH_ASSETS}${PATH_ASSETS_PERSONAL_AREA}address.svg`,
    buonispesa: `${PATH_ASSETS}${PATH_ASSETS_PERSONAL_AREA}buonispesa-illustration.svg`,
    help: `${PATH_ASSETS}${PATH_ASSETS_PERSONAL_AREA}help.svg`,
    newsletter: `${PATH_ASSETS}${PATH_ASSETS_PERSONAL_AREA}newsletter.svg`,
    ordini: `${PATH_ASSETS}${PATH_ASSETS_PERSONAL_AREA}ordini-icon.png`,
    personalData: `${PATH_ASSETS}${PATH_ASSETS_PERSONAL_AREA}personal_data.svg`,
  };

  export const ICONE_MENU: AssetImage = {
    addressM: `${PATH_ASSETS}${PATH_ASSETS_MENU}address-m.svg`,
    address: `${PATH_ASSETS}${PATH_ASSETS_MENU}address.png`,
    buonoBigM: `${PATH_ASSETS}${PATH_ASSETS_MENU}buono-big-m.svg`,
    buonoBig: `${PATH_ASSETS}${PATH_ASSETS_MENU}buono-big.png`,
    dashboardM: `${PATH_ASSETS}${PATH_ASSETS_MENU}dashboard-m.svg`,
    dashboard: `${PATH_ASSETS}${PATH_ASSETS_MENU}dashboard.png`,
    helpM: `${PATH_ASSETS}${PATH_ASSETS_MENU}help-m.svg`,
    help: `${PATH_ASSETS}${PATH_ASSETS_MENU}help.png`,
    logoutM: `${PATH_ASSETS}${PATH_ASSETS_MENU}logout-m.svg`,
    logout: `${PATH_ASSETS}${PATH_ASSETS_MENU}logout.png`,
    newsletterM: `${PATH_ASSETS}${PATH_ASSETS_MENU}newsletter-m.svg`,
    newsletter: `${PATH_ASSETS}${PATH_ASSETS_MENU}newsletter.png`,
    ordersM: `${PATH_ASSETS}${PATH_ASSETS_MENU}orders-m.svg`,
    orders: `${PATH_ASSETS}${PATH_ASSETS_MENU}orders.png`,
    personalDataM: `${PATH_ASSETS}${PATH_ASSETS_MENU}personal_data-m.svg`,
    personalData: `${PATH_ASSETS}${PATH_ASSETS_MENU}personal_data.png`,
  };

  export const WAITING_LIST: AssetImage = {
    waitingList: `${PATH_ASSETS}${PATH_ASSETS_WAITING_LIST}waiting-list.svg`,
    wait: `${PATH_ASSETS}${PATH_ASSETS_WAITING_LIST}wait.svg`,
    success: `${PATH_ASSETS}${PATH_ASSETS_WAITING_LIST}success.svg`,
    walk: `${PATH_ASSETS}${PATH_ASSETS_WAITING_LIST}walking-man.gif`,
  };

  export const CONTROL_CODE: string = `${PATH_ASSETS}controlCode.jpeg`
  export const MAIL_OUTLINE: string = `${PATH_ASSETS}mail-outline.svg`

