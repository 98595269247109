import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Address } from 'src/entities/address';
import { AddressService } from '../service/address.service';
import { RouteService } from '../service/route.service';

@Component({
  selector: 'app-address-detail',
  templateUrl: './address-detail.component.html',
  styleUrls: ['./address-detail.component.scss'],
})
export class AddressDetailComponent implements OnInit {
  addressDataForm!: UntypedFormGroup;
  id: number = 0;
  isNew: boolean = false;
  addresses: Address[];
  address?: Address;

  constructor(
    private _fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private _addressService: AddressService,
    private _route: RouteService,
  ) {
    this.isNew = (this.route.snapshot.params['id']) ? false : true;
    this.addresses = this._addressService.addresses$.getValue();
    this.address = this.addresses.find((a) => a.id == this.route.snapshot.params['id'] );
    if (this.address) this.initForm(this.address);
  }

  onSubmit() {
    if (this.addressDataForm.valid) {
      this.addressDataForm.reset();
    }
  }

  initForm(address: Address) {
    let validationFormGroup;
    if(address) {
      validationFormGroup ={
        firstName: [address.nom, Validators.required],
        lastName: [address.cog, Validators.required],
        company: [''],
        phone: ['', Validators.required],
        fax: [''],
        address: [address.address, Validators.required],
        zip: [address.cap],
        city: [address.comune, Validators.required],
        province: [address.prov, Validators.required],
        fiscalCode: ['', Validators.required],
        piva: [''],
      };
    } else {
      validationFormGroup ={
        firstName: [(''), Validators.required],
        lastName: [(''), Validators.required],
        company: [('')],
        phone: [(''), Validators.required],
        fax: [''],
        address: [(''), Validators.required],
        zip: [('')],
        city: [(''), Validators.required],
        province: [(''), Validators.required],
        fiscalCode: [(''), Validators.required],
        piva: [''],
      };
    }



    this.addressDataForm = this._fb.group(validationFormGroup);
  }

  cameBack() {
    this._route.back();
  }

  ngOnInit(): void { }
}
