import { ActivatedRoute, Router } from '@angular/router';
import { AssetImage } from 'src/entities/assetImage';
import { Component } from '@angular/core';
import { DeviceService } from 'src/app/service/device.service';
import { LoginService } from 'src/app/service/login.service';
import { LOGO } from 'src/config/constats';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { RouteService } from 'src/app/service/route.service';
import { SocialAuthService, FacebookLoginProvider, GoogleLoginProvider } from '@abacritt/angularx-social-login';
import { StorageService } from 'src/app/service/storage.service';
import { switchMap, map } from 'rxjs';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { User } from 'src/entities/user';
import { WAITING_LIST } from 'src/config/images';
import { WaitlistService } from 'src/app/service/waitlist.service';

@Component({
  selector: 'app-waiting-list-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  assetImg: AssetImage = WAITING_LIST;
  isMobile: boolean = true;
  loader: boolean = false;
  loginDataForm!: UntypedFormGroup;
  logoUrl: string = LOGO;
  show_button: boolean = false;
  show_eye: boolean = false;

  constructor(
    private _fb: UntypedFormBuilder,
    private _loginService: LoginService,
    private _storageService: StorageService,
    private _waitlistService: WaitlistService,
    private readonly authService: SocialAuthService,
    private recaptchaV3Service: ReCaptchaV3Service,
    private route: ActivatedRoute,
    private router: Router,
    public _detectorService: DeviceService,
    public _routeService: RouteService,
  ) {
    this.initForm();

    this.route.queryParamMap.subscribe(params => {
      const data ={
        token: params.get('token') || '',
        shop: params.get('shop') || '',
        department: params.get('department') || '',
      };
      this._waitlistService.setDataWl(data)
      this._storageService.setItem('dataWl',data, 3600);
    });

    this.loginDataForm.controls['email'].valueChanges
      .subscribe(x => {
        if (x && x.includes(' ')) {
          this.loginDataForm.controls['email'].setValue(x.trim())
        }
      })
    this.isMobile = this._detectorService._isMobile.getValue();
  }

  ngOnInit() {
    // service for social login
    this._waitlistService.setIsRedirect(true);

    this.authService.authState.subscribe((response) => {
      if (response) {
        const user: User = new User;
        let token: string = '';
        if (response.provider === 'FACEBOOK') {
          token = response.authToken;
        }

        if (response.provider === 'GOOGLE') {
          token = response.idToken;
        }

        const { email, lastName, firstName } = response;

        this._storageService.setItem('personaldata',{
          firstname: firstName,
          lastname: lastName,
          email
        }, 3600);

        this._waitlistService.setGuest(false);
        this._routeService.goTo('lista-attesa-form');
      }
    });
  }

  signInWithFB(): void {
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
  }

  refreshGoogleToken(): void {
    this.authService.refreshAuthToken(GoogleLoginProvider.PROVIDER_ID);
  }

  onSubmit() {
    if (this.loginDataForm.valid) {
      let res: number = 0;
      this._loginService.loginWl(this.loginDataForm.value.email.trim(), this.loginDataForm.value.password).pipe(
        switchMap((response: number) => {
          res = response;
          return this.recaptchaV3Service.execute('login');
        }),
        map((token: string) => {
          if (!token) this._routeService.goTo('lista-attesa');
          return res;
        }),
        map((response: number) => {
          switch (response) {
            case 200:
              this.loginDataForm.reset();
              this._routeService.goTo('lista-attesa-form');
              break;
            default:
              /*
                401: "Email o password errate"
                402: "Email o password vuote"
                403: "Account non registrato"
                404: "Opt-in non confermato"
                405: "Account bloccato"
              */
              break;
          }
        }),
      ).subscribe();
    }
  }

  initForm() {
    this.loginDataForm = this._fb.group({
      email: ['', [Validators.pattern("^[a-zA-Z0-9._|\\\\%#~`=?&/$^*!}{+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z ]{2,4}$"), Validators.required]],
      password: ['', Validators.required],
    });
  }

  goToForm() {
    this._waitlistService.setGuest(true);
    this._routeService.goTo('lista-attesa-form')
  }

  showPassword() {
    this.show_button = !this.show_button;
    this.show_eye = !this.show_eye;
  }
}
