import { Address } from "src/entities/address";
import { AddressService } from "./address.service";
import { Injectable } from "@angular/core";
import { Order } from "src/entities/order";
import { UtilityService } from "./utility.service";
import { Detail } from "src/entities/detail";

@Injectable({
  providedIn: 'root',
})
export class OrdiniUtiltyService {
  constructor(
    private _addressService: AddressService,
    private _utilityService: UtilityService
  ) {
  }

  /**
  * Reformat initial object
  * @param {Array} orders : string to retrive order information
  * @return {Order[]} Return a object ordine reformatted.
  * @public
  */
   public refactoringObjOrder(orders: any[]) {
    let newObjOrders: Order[] = [];
    let addresses: Address[] = [];
    let progressiveId = 1;

    let orderWithRevision = new Map<string, number>();

    if (orders.length == 0) {
      this._addressService.addresses$.next([]);
      this._addressService.addressIsEmpty$.next(true);
      return [];
    }

    orders.forEach((o: any) => {
      if (o.ordini.length == 0) return;
      addresses.push({
        "id": progressiveId,
        "cap": o.cap,
        "civico": o.civico,
        "cog": o.cog,
        "comune": o.comune,
        "ind": o.ind,
        "indlib": o.indlib,
        "indplus": o.indplus,
        "nom": o.nom,
        "prov": o.prov,
      });

      o.ordini.forEach((oe: any) => {
        if (oe.dettagli.length > 0) {
          const statoKey = oe.stato;
          const consegnaKey = oe.consegna;

          this._utilityService.updateObjValue(oe, orderWithRevision);

          newObjOrders.push({
            "cap": o.cap,
            "cazi": o.cazi,
            "ccli": o.ccli,
            "civico": o.civico,
            "cli_id": o.cli_id,
            "cog": o.cog,
            "comune": o.comune,
            "emailP": o.email,
            "guid": o.guid,
            "idsales": o.idsales,
            "idsalesm": o.idsalesm,
            "capf": o.capf,
            "cittaf": o.cittaf,
            "indf": o.indf,
            "codfisc": o.codfisc,
            "ind": o.ind,
            "indlib": o.indlib,
            "indplus": o.indplus,
            "nazione": o.nazione,
            "clinaz": o.clinaz,
            "nom": o.nom,
            "prov": o.prov,
            "timestamp": o.timestamp,
            "statoKey": statoKey,
            "consegnaKey": consegnaKey,
            ...oe,
          });
        }
      })

      progressiveId++;
    });

    if(orderWithRevision.size > 0) this.iterationOrderForRevision(orderWithRevision, newObjOrders);

    this._addressService.addresses$.next(addresses);
    if (addresses.length === 0) this._addressService.addressIsEmpty$.next(true);
    return newObjOrders;
  }

  iterateDettaglio (o: Order) {
    let elementi: Detail[] = [];
    let isCcod = false;
    if (o.dettagli && o.dettagli.length > 0) {
      o.dettagli.forEach((d: Detail) => {
        if (d.ccod && !isCcod) isCcod = true;
        if (!d.ccod && isCcod) elementi.push(d);
      })

      if (elementi.length > 0 && o.dettagli) {
        o.dettagli[0].elementi = elementi;
        const firstDettaglio = o.dettagli[0];
        o.dettagli = [];
        o.dettagli[0] = firstDettaglio;
      }
    }
  }

  iterationOrderForRevision(orderWithRevision:Map<string,number>, orders: any): void {
    // console.debug(orderWithRevision);
    orders.forEach((oe: any) => {
      if(orderWithRevision.has(oe.prbu) && this._utilityService.checkIsRevisione(oe.rev) && orderWithRevision.size > 0) {
        const val = orderWithRevision.get(oe.prbu);
        if (val && val > 1){
          oe.isRevisionWithAppointment = true;
        } else {
          /** se bc idraulico forzo la revisione in caso di 1 bc in array di controllo */
          oe.isRevisionWithAppointment = (oe.idraulico) ? true : false;
        }
      }
      // console.debug(oe.prbu + " | " + oe.rev + " | " + oe.isRevisionWithAppointment);
    });
  }
}

