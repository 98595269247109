import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})

export class EncryptionService {
  private salt = 'Nt0ySuwBv7MZzMCgMDPx63BJj79Kzc74'

  /**
    * Encrypt a password using AES-128-CBC with a derived key.
    * @param password
    * @returns string.
    */
  encrypt(password: string): string {
    const iv = CryptoJS.lib.WordArray.random(16);

    const key = CryptoJS.PBKDF2(this.salt, CryptoJS.enc.Utf8.parse(this.salt), {
      keySize: 16 / 4,
      iterations: 1000,
    });

    const encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(password), key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });

    const result = {
      iv: iv.toString(CryptoJS.enc.Hex),
      encryptedData: encrypted.ciphertext.toString(CryptoJS.enc.Base64),
    };

    return JSON.stringify(result);
  }

  generateRandomString(maxLength: number): string {
    const possibleChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!£$%&/';
    let randomString = '';

    for (let i = 0; i < maxLength; i++) {
      randomString += possibleChars.charAt(Math.floor(Math.random() * possibleChars.length));
    }

    return randomString;
  }
}
