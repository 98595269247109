import { ApiService } from './service/api.service';
import { AutoLoginVsMagento } from './service/autoLoginVsMag.service';
import { Component } from '@angular/core';
import { DeviceService } from './service/device.service';
import { filter } from 'rxjs/operators';
import { LoadingService } from './service/loading.service';
import { LocalizationService } from './service/localization.service';
import { NavigationEnd, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { OrdiniService } from './service/ordini.service';
import { RouteService } from './service/route.service';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from './service/user.service';
import { VouchersService } from './service/vouchers.service';
import { whitelistDomain } from './../config/router';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  currentUrl: string = '';
  img: string = '';
  loading$: Observable<boolean>;
  mobileMenuState: boolean = false;
  public isMobile: boolean = false;
  vouchersCounter$!: Subject<number>;
  notValidDomain: boolean = false;

  constructor(
    private router: Router,
    public _apiService: ApiService,
    public _autoLoginVsMagentoService: AutoLoginVsMagento,
    public _detectorService: DeviceService,
    public _loadingService: LoadingService,
    public _localizationService: LocalizationService,
    public _ordiniService: OrdiniService,
    public _routeService: RouteService,
    public _translate: TranslateService,
    public _userService: UserService,
    public _vouchersService: VouchersService,
  ) {
    router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(
      (event: any) => {
        // faccio questo controllo perche da magento torna un url con accodato una string
        if (!whitelistDomain.includes(event.url)) this.notValidDomain = true;
        this.currentUrl = ((event.url).includes('?_gl=')) ? '/' :event.url;
      }
    );

    _apiService.isLoggedObs$.subscribe();
    _apiService.orderObs$.subscribe();
    _autoLoginVsMagentoService.autoLoginVsMagento$.subscribe();
    _detectorService.init();
    _localizationService.init();
    _ordiniService.ordini$.subscribe();
    _userService.fullnameObs$.subscribe();
    _vouchersService.vouchersObs$.subscribe();
    this.img = _localizationService.getImageByLang();
    this.isMobile = _detectorService._isMobile.getValue();
    this.loading$ = _loadingService.loading$;
    this.vouchersCounter$ = this._vouchersService.vouchersCounter$;
  }

  mobileMenuStateEvent(state: boolean): void {
    this.mobileMenuState = state;
  }

  closeMobileMenu() {
    this.mobileMenuState = false;
  }

  goToOrders () {
    this.router.navigate(['/orders']);
  };

  goToVouchers () {
    this.router.navigate(['/vouchers']);
  };
}
