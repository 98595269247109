import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { RESERVATION } from 'src/config/images';
import { ReservationService } from '../service/reservation.service';
import { UserService } from '../service/user.service';

@Component({
  selector: 'app-banner-reservation',
  templateUrl: './banner-reservation.component.html',
  styleUrls: ['./banner-reservation.component.scss'],
})
export class BannerReservationComponent {
  isBannerHidden:boolean = false;
  assetImgMail:string = RESERVATION['mail'];

  orders$!: Observable<string[]>; 
  fullname: any = '';

  constructor(
    private _userService: UserService,
    private _reservationService: ReservationService,
  ) {
    this.fullname = this._userService.fullname$;
    this.orders$ = this._reservationService.reservation$;
  }

  hideBanner() {
    this.isBannerHidden = true;
  }

  copyText(txt: string) {
    if (!document.hasFocus()) {
      return;
    }  
    
    navigator.clipboard.writeText(txt);
  }
}
