<div class="Header-Wrapper">
  <header>
    <nav class="Header-Nav">
      <a aria-label="Go to homepage" href="{{ mondoConv | translate}}" class="Image-Logo" target="_blank">
        <img src='{{ logoUrl |translate}}' alt="Logo" />
      </a>
      <div class="icon-gap d-flex">
        <div aria-label="Punto vendita" class="Header-StoreLocator">
          <div class="Header-StoreLocatorWrapper">
            <a
              aria-label="Punto vendita"
              [href]="magentoUrl+links.puntiVendita"
              class="Header-Link"
              target="_blank"
            >
              <i class="bi bi-geo-alt"></i>
              <span class="Header-StoreLocatorTitle">{{ "actions.ctaStores" | translate }}</span>
            </a>
          </div>
        </div>
        <div aria-label="Info" class="Header-Info">
          <div class="Header-InfoWrapper">
            <a aria-label="Info" [href]="magentoUrl+links.faq" class="Header-Link" target="_blank">
              <i class="bi bi-info-circle"></i>
              <span class="Header-InfoTitle">{{ "header.ctaInfo" | translate }}</span>
            </a>
          </div>
        </div>
        <div aria-label="Catalogo" class="Header-Catalogo">
          <div class="Header-CatalogoWrapper">
            <a
              aria-label="Catalogo"
              [href]="magentoUrl+links.catalogo"
              class="Header-Link"
              target="_blank"
            >
              <i class="bi bi-book"></i>
              <span class="Header-CatalogoTitle">{{ "header.ctaCatalog" | translate }}</span>
            </a>
          </div>
        </div>
      </div>
    </nav>
  </header>
</div>
<div id="header" class="bg-primary text-white" [ngClass]="{'menu_open': mobileMenuOpen}">
  <div class="container h-100">
    <div class="row h-100 d-flex align-items-center">
      <div [ngClass]="isMobile ? 'col-8' : 'col-6'">
        <h3 class="h1_title benvenuto_text" *ngIf="!(this._apiService.isGuest$ | async) && !notValidDomain ;else isNotGuest">
          <!-- {{ 'header.welcome' | translate | uppercase }} -->
          {{ 'header.hello' | translate: { 'fullname': fullname$ | async  } }}
        </h3>
        <ng-template #isNotGuest>
          <h3 class="h1_title benvenuto_text">
            {{ 'header.welcome' | translate | uppercase }}
          </h3>
        </ng-template>
      </div>
      <div class="d-sm-flex justify-content-end" [ngClass]="isMobile ? 'col-4' : 'col-6'" *ngIf="!!(_apiService.isLogged$ | async) && !notValidDomain">
        <div
        id="hamburger_menu"
        class="bg-white d-flex align-items-center justify-content-center"
        (click)="toggleMobileMenu()"
        [ngClass]="{'active': mobileMenuOpen}">
          <span class="d-block"></span>
        </div>
      </div>
      <div class="d-sm-flex justify-content-end col-4 padding-mobile-access" *ngIf="isGuest && isMobile">
        <a role="button" (click)="exitMenu()">
          <p class="py-3 d-flex flex-row justify-content-start align-items-start guest-access">
            <!-- <img [src]="iconMobileExit" alt="logout" class="me-2" width="14px"> -->
            {{ 'menu.access' | translate | uppercase }}
          </p>
        </a>
      </div>
      <div class="col-md-6 text-end d-md-block" *ngIf="!isGuest">
        <h3 class="h1_title mio_account">{{ 'header.title' | translate }}</h3>
      </div>
      <div class="col-md-6 text-end d-md-block" *ngIf="isGuest && !isMobile">
        <button class="btn btn-blue-reverse m-0" (click)="exitMenu()">
          {{ 'menu.access' | translate | uppercase }}
        </button>
      </div>
    </div>
  </div>
</div>
