import { AdminService } from '../service/admin.service';
import { Component, EventEmitter, Output, ViewContainerRef } from '@angular/core';
import { DeviceService } from '../service/device.service';
import { LoginAdminFailService } from '../service/modal/loginAdminFail.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, } from '@angular/forms';

@Component({
  selector: 'app-login-box-admin',
  templateUrl: './login-box-admin.component.html',
  styleUrls: ['./login-box-admin.component.scss'],
})
export class LoginBoxAdminComponent {
  @Output() changeLoginBox = new EventEmitter<boolean>();

  isMobile: boolean = false;
  loggedIn: boolean = false;
  loginAdminDataForm!: UntypedFormGroup;
  showModal: boolean = false;
  show_button: boolean = false;
  show_eye: boolean = false;

  host: string = "";
  loader: boolean = false;

  constructor(
    private _adminService: AdminService,
    private _fb: UntypedFormBuilder,
    private _loginAdminFailService: LoginAdminFailService,
    private _translate: TranslateService,
    private route: Router,
    private viewContainerRef: ViewContainerRef,
    public _detectorService: DeviceService
  ) {
    this.initForm();

    switch (window.location.host) {
      case 'account-sg.mondoconv.it':
        this.host = 'https://staging247.mondoconv.it';
        break;

      case 'account.mondoconv.it':
        this.host = 'https://www.mondoconv.it';
        break;

      default:
        this.host = 'https://www.mondoconv.it';
        break;
    }

    this.loginAdminDataForm.controls['email'].valueChanges
      .subscribe(x => {
        if (x && x.includes(' ')) {
          this.loginAdminDataForm.controls['email'].setValue(x.trim())
        }
      })
    this.isMobile = this._detectorService._isMobile.getValue();
  }

  ngOnInit() { }

  onSubmit() {
    if (this.loginAdminDataForm.valid) {
      this._adminService.login(this.loginAdminDataForm.value.email.trim(), this.loginAdminDataForm.value.password).subscribe((response: any) => {
        if (response && response === 200) {
          this.loginAdminDataForm.reset();
          this.route.navigateByUrl('search');
        } else {
          const fakeEventClick: Event = new MouseEvent('click');
          this.openModal(fakeEventClick);
        }
      });
    }
  }

  openModal(e: Event) {
    e.preventDefault();
    this._loginAdminFailService.setRootViewContainerRef(this.viewContainerRef);
    this._loginAdminFailService.addDynamicComponent();
  }

  initForm() {
    this.loginAdminDataForm = this._fb.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  showPassword() {
    this.show_button = !this.show_button;
    this.show_eye = !this.show_eye;
  }
}
