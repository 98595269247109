import {
  ACCEPTED_STATE,
  CANALE,
  CONSEGNA_GOLD,
  CONSEGNA,
  DES_ADDEBITO_TRASPORTO_2,
  DES_ADDEBITO_TRASPORTO,
  DES_COSTI_GIACENZA,
  DES_DOLCE_CASA,
  DES_GEOMETRA,
  DES_NAVETTA,
  DES_RIPROGRMMAZIONE,
  DES_RITIRO_MERCE,
  DES_SISTEMAZIONE_2,
  DES_SISTEMAZIONE,
  DES_VIAGGIO,
  REG_PADRE,
  REG_REVISONE,
  REG_SDOPPIATO,
  STATE,
  NAVETTA,
} from "src/config/constats";
import { CustomCookieService } from "./customCookie.service";
import { Detail } from "src/entities/detail";
import { environment } from "src/environments/environment";
import { Injectable } from "@angular/core";
import { Order } from "src/entities/order";
import { Params } from "@angular/router";
import { SocialAuthService } from "@abacritt/angularx-social-login";

@Injectable({
  providedIn: 'root'
})

export class UtilityService {
  baseUrlImg = `${environment.middlewareIt}${environment.apiPaths.img}`;

  constructor(
    private _authService: SocialAuthService,
    private _customCookieService: CustomCookieService,
  ) { }

  /**
   * function to convert consegna string
   * @param {string} consegna
   * @return {string}
   * @public
   */
  getConsegna(k: string) {
    return CONSEGNA[k];
  }

  /**
   * function to convert canale string
   * @param {string} canale
   * @return {string}
   * @public
   */
  getCanale(k: string, ks: string) {
    if (k == 'T' && ks != '') {
      if (ks.toLowerCase() == 'televendita') return 'Ordine telefonico';
      if (ks.toLowerCase() == 'store') return 'Punto vendita';
      return ks;
    }

    if (k == 'N' && ks != '') {
      if (ks.toLowerCase() == 'store') return 'Punto vendita';
      return ks;
    }

    return (ks != '') ? `${CANALE[k]} - ${ks}` : CANALE[k];
  }

  /**
   * function to convert canale string
   * @param {string} canale
   * @return {string}
   * @public
   */

  /*
  - Trasporto e Montaggio ‘P’
  - Ritiro Cliente ‘R’
  - Corriere Nazionale ‘C’
  - Sotto Casa Tua ‘S’
   */
  getStato(param: {
    k: string,
    consegna: string,
    perapp: boolean,
    dccorr: string | null,
    dcc: string | null,
    dapp: string | null,
    dcci: string | null,
    fasciacom: string | null,
    cfgold: number
  }) {
    const {
      k,
      consegna,
      dccorr,
      perapp,
      dcc,
      dapp,
      dcci,
      fasciacom,
      cfgold
    } = param;

    if (k === 'consegnato' && consegna == 'R') return "ritirato";

    if (k === 'consegna' && consegna == 'C' && dccorr != null) return "ritirato corriere";
    if (k === 'consegna' && consegna == 'C') return "in lavorazione";
    if (k === 'consegna') {
      const fasciaComm = (cfgold > 0) ? CONSEGNA_GOLD[cfgold] : fasciacom;

      let dateToUse = '';
      if (dcci != null) dateToUse = dcci;
      if (dapp != null) dateToUse = dapp;

      return `In consegna il ${dateToUse.replaceAll('/', '-')} nella fascia oraria ${fasciaComm}`;
    }

    if (k === 'appuntamento' && consegna == 'C' && dccorr != null) return "in lavorazione"; // se la se now < dccorr
    if (k === 'appuntamento' && consegna == 'C' && dccorr != null) return "ritirato corriere";
    if (k === 'appuntamento' && dapp != null) return `appuntamento il ${dapp.replaceAll('/', '-')}`;
    if (k === 'appuntamento' && consegna == 'R') return "pronto per l'appuntamento in magazzino";

    if (k === 'pronto' && consegna == 'R') return "pronto per l'appuntamento al ritiro";
    if (k === 'pronto' && (!perapp || consegna == 'C')) return "in lavorazione";

    return STATE[k];
  }

  getOrderDetailByParams(params: Params, orders: Array<Order>) {
    const rev = params.orderId.slice(-1);
    const prbu = params.orderId.substring(0, params.orderId.length - 1);

    const order = orders.find((el: Order) => {
      return el.prbu === prbu && el.rev === rev;
    })

    return order;
  }

  /**
  * Convert status, consegna and boolean flag based desc
  * @param {Array} orders : string to retrive order information
  * @return {Order[]} Return a object ordine reformatted.
  * @private
  */
  updateObjValue(oe: Order, orderWithRevision: Map<string,number>) {
    /** Forzo l'assegnazione per lo stato navetta */
    if ((oe.consdes).toLowerCase() === NAVETTA)  oe.consegna = 'N';

    if (oe.codmcdep == 900) oe.isDropOff = true;
    if (oe.codmcdep == 47) oe.isCatanzaroDep = true;

    /** forzo lo stato in caso di ordine Bruciato */
    if (oe.annullato) oe.stato = ACCEPTED_STATE[5];

    oe.canale = this.getCanale(oe.canale, oe.subcanale);
    oe.stato = this.getStato({
      k: oe.stato,
      consegna: oe.consegna,
      dccorr: this.dateConverter(oe.dccorr),
      perapp: oe.perapp,
      dcc: this.dateConverter(oe.dcc),
      dapp: this.dateConverter(oe.dapp),
      dcci: this.dateConverter(oe.dcci),
      fasciacom: oe.fasciacom,
      cfgold: oe.cfgold
    });

    oe.consegna = this.getConsegna(oe.consegna);

    if (oe.dettagli && oe.dettagli.length > 0) {
      oe.dettagli.forEach((dettaglio: Detail) => {
        if ((dettaglio.des).toLowerCase() === DES_NAVETTA)  oe.navetta = true;
        if ((dettaglio.des) === DES_RIPROGRMMAZIONE)  oe.isRiprogrammazione = true;
        if ((dettaglio.des).toLowerCase() === DES_SISTEMAZIONE ||
        (dettaglio.des).toLowerCase() === DES_SISTEMAZIONE_2) oe.isSistemazione = true;
        if ((dettaglio.des).toLowerCase().includes(DES_COSTI_GIACENZA)) oe.isCostiGiacenza = true;
        if ((dettaglio.des).toLowerCase() === DES_RITIRO_MERCE) oe.isRitiroMerce = true;
        if ((dettaglio.des).toLowerCase().includes(DES_GEOMETRA)) oe.isGeometra = true;
        if ((dettaglio.des).toLowerCase() === DES_DOLCE_CASA) oe.isDolceCasa = true;
        if (((dettaglio.des).toLowerCase() === DES_ADDEBITO_TRASPORTO || (dettaglio.des).toLowerCase() === DES_ADDEBITO_TRASPORTO_2) && !dettaglio.trasporto) oe.isAddebitoTrasporto = true;
        if ((dettaglio.des).toLowerCase() === DES_VIAGGIO) oe.isTraghetto = true;
        dettaglio.consegna = this.getConsegna(dettaglio.consegna);
        dettaglio.imageUrl = this.assignImageToProduct(dettaglio, oe);
      });
    }

    oe.isRevisionWithAppointment = this.isAppointmentOnRevision(oe);

    if (this.isAppointmentOnRevision(oe)) {
      let val:number | undefined = 0;
      let increment = 0;

      if(orderWithRevision != undefined && orderWithRevision.has(oe.prbu)) {
        val = orderWithRevision.get(oe.prbu);
        if (val != undefined) increment = val+1;
      } else {
        increment = 1;
      }

      orderWithRevision.set(oe.prbu, increment)
    };
  }

  private isAppointmentOnRevision(order:Order) : boolean {
    // "dapp": vuota ; "rev"= lettera; "stato" <> "annullato" ; !annullato; idraulico
      return (order.dapp == '' || order.dapp == null) &&
      this.checkIsRevisione(order.rev) &&
      order.stato != ACCEPTED_STATE[5] &&
      !order.annullato;
  }

  /**
  * Reformat iso date to localeDatestring
  * @param isoDate
  * @return date
  * @private
  */
  private dateConverter(isoDate: string | number | Date | null) {
    if (!isoDate) return null;
    const d = new Date(isoDate);
    return d.toLocaleDateString('it-IT');
  }

  isOrderAfterGoLive(orderDbu: string): boolean {
    if (!orderDbu) return false;

    const dateOrder = new Date(orderDbu);
    const orderDate = new Date(dateOrder.getFullYear(), dateOrder.getMonth(), dateOrder.getDate());
    const goLiveDate = new Date(2023, 5, 21);
    return orderDate.getTime() >= goLiveDate.getTime();
  }

  exitMenu(): void {
    this._customCookieService.deleteCookie([
      'auth_token',
      'auth_token_sso',
      'customer',
      'user_firstname',
      'user_lasttname'
    ]);

    this._customCookieService.setCookie([{
      key: 'controlCookie',
      value: "false"
    }]);

    this._authService.signOut();
    window.location.reload();
  }

  checkIsRevisione(rev:string): boolean {
    return REG_REVISONE.test(rev);
  }

  checkIsSdoppiato(rev:string): boolean {
    return REG_SDOPPIATO.test(rev);
  }

  checkIsParteOrdine(rev:string): boolean{
    return REG_PADRE.test(rev);
  }

  /**
   * UNUSED
   * @param order
   * @returns string
   */
  getUrlCoordinates(order: Order) {
    const { latitudePv, longitudePv, latitudeDep, longitudeDep } = order;

    const latitude = latitudeDep || latitudePv;
    const longitude = longitudeDep || longitudePv;

    if (!latitude || !longitude) return ''; // aggiugnere default

    return `https://www.google.com/maps/search/?api=1&query=${latitude}%2C${longitude}`;
  }

  getShowEditAppointment(
    statoKey: string,
    perapp: boolean,
    annullato: boolean,
    catesterno: boolean,
    isBoxOrder:boolean,
    dateutili: string,
    isCatanzaroDep: boolean,
    isRevisionWithAppointment: boolean) : boolean {
      return statoKey == ACCEPTED_STATE[8] && perapp && dateutili != '' && !annullato && !catesterno && isBoxOrder && !isCatanzaroDep && !isRevisionWithAppointment;
  }

  // remove isREvisione
  getShowCreateAppointment(
    statoKey: string,
    perapp: boolean = false,
    annullato: boolean = false,
    catesterno: boolean = false,
    isBoxOrder:boolean = false,
    isCatanzaroDep: boolean = false,
    isRevisionWithAppointment: boolean = false) : boolean {
      const acceptedStateAppointment = (statoKey == ACCEPTED_STATE[9] || statoKey == ACCEPTED_STATE[4]);
      return acceptedStateAppointment && perapp && !annullato && !catesterno && isBoxOrder && !isCatanzaroDep && !isRevisionWithAppointment;
  }

  assignImageToProduct(detail: Detail, order: Order): string {
    const pathImgServizio = '/assets/img/';

    if (order.isRiprogrammazione) return `${pathImgServizio}riprogrammazione.png`;
    if (order.isGeometra) return `${pathImgServizio}geometra.png`;
    if (order.isDolceCasa) return `${pathImgServizio}dolcecasa.png`;
    if (order.isCostiGiacenza) return `${pathImgServizio}giacenza.png`;
    if (order.isAddebitoTrasporto) return `${pathImgServizio}addebito.png`;
    if (order.isTraghetto)  return `${pathImgServizio}traghetto.png`;
    if (order.fuorizona) return `${pathImgServizio}fuorizona.png`;

    return (detail.hasImage) ? `${this.baseUrlImg}${detail.sid}?width=200` : `${pathImgServizio}placeholder.png`;
  }
}
