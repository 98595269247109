import { Component, Input, OnInit } from '@angular/core';
import { UtilityService } from '../service/utility.service';

@Component({
  selector: 'app-order-delivery',
  templateUrl: './order-delivery.component.html',
})

export class OrderDeliveryComponent implements OnInit {
  @Input() isMobile: boolean = false;
  @Input() isMsgRev: boolean = false;

  constructor(
    private _utilityService: UtilityService
  ) {}

  ngOnInit(): void {}

  goToChat(evt?: MouseEvent) {
    if (evt){
      const target = evt.target as Element;
      if (target && target.classList.contains('btn-action-wa')) {
        evt.preventDefault();
        this._utilityService.openChat();
      }
    } else {
      this._utilityService.openChat();
    }
  }
}