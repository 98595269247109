import { DateConverter } from './converter/date-converter';
import { JsonObject, JsonProperty } from 'json2typescript';
import { Detail } from './detail';
import { Voucher } from './voucher';

@JsonObject('Order')
export class Order {
  @JsonProperty('cap', String)
  public cap?: string = '';
  @JsonProperty('cazi', Number)
  public cazi?: number = 0;
  @JsonProperty('ccli', Number)
  public ccli?: number = 0;
  @JsonProperty('civico', Number)
  public civico?: number = 0;
  @JsonProperty('cli_id', String)
  public cli_id?: null | string = '';
  @JsonProperty('cog', String)
  public cog?: string = '';
  @JsonProperty('comune', String)
  public comune?: string = '';
  @JsonProperty('emailP', String)
  public emailP?: string = '';
  @JsonProperty('guid', String)
  public guid: string = '';
  @JsonProperty('idsales', String)
  public idsales?: string = '';
  @JsonProperty('idsalesm', String)
  public idsalesm?: string = '';
  @JsonProperty('ind', String)
  public ind?: string = '';
  @JsonProperty('indlib', String)
  public indlib: string = '';
  @JsonProperty('indplus', String)
  public indplus: string = '';
  @JsonProperty('nom', String)
  public nom?: string = '';
  @JsonProperty('prov', String)
  public prov?: string = '';
  @JsonProperty('timestamp', String)
  public timestamp?: string = '';
  @JsonProperty('annullato', Boolean)
  public annullato: boolean = false;
  @JsonProperty('assban', Boolean)
  public assban: boolean = false;
  @JsonProperty('assfull', Boolean)
  public assfull: boolean = false;
  @JsonProperty('canale', String)
  public canale: string = '';
  @JsonProperty('subcanale', String)
  public subcanale: string = '';
  @JsonProperty('ccons', Number)
  public ccons: number = 0;
  @JsonProperty('cfgold', Number)
  public cfgold: number = 0;
  @JsonProperty('cliente', String)
  public cliente: string = '';
  @JsonProperty('codmcdep', Number)
  public codmcdep: number = 0;
  @JsonProperty('codmcpv', Number)
  public codmcpv: number = 0;
  @JsonProperty('consegna', String)
  public consegna: string = '';
  @JsonProperty('capf', String)
  public capf: string = '';
  @JsonProperty('cittaf', String)
  public cittaf: string = '';
  @JsonProperty('indf', String)
  public indf: string = '';
  @JsonProperty('codfisc', String)
  public codfisc: string = '';
  @JsonProperty('dapp', DateConverter)
  public dapp: string | null = '';
  @JsonProperty('dateutili', String)
  public dateutili: string = '';
  @JsonProperty('dbu', DateConverter)
  public dbu: string = '';
  @JsonProperty('dcc', DateConverter)
  public dcc: string | null = '';
  @JsonProperty('dcci', DateConverter)
  public dcci: string = '';
  @JsonProperty('dccorr', String)
  public dccorr: null | string = '';
  @JsonProperty('dccp', DateConverter)
  public dccp: string | null = '';
  @JsonProperty('dcspedpf', String)
  public dcspedpf: null | string = '';
  @JsonProperty('dcspedpi', String)
  public dcspedpi: null | string = '';
  @JsonProperty('email', String)
  public email: string = '';
  @JsonProperty('fasciacom', String)
  public fasciacom: string = '';
  @JsonProperty('fattura', String)
  public fattura: string = '';
  @JsonProperty('iban', String)
  public iban: string = '';
  @JsonProperty('idsped', String)
  public idsped: string = '';
  @JsonProperty('nocash', Boolean)
  public nocash: boolean = false;
  @JsonProperty('norange', Boolean)
  public norange: boolean = false;
  @JsonProperty('perann', Boolean)
  public perann: boolean = false;
  @JsonProperty('perapp', Boolean)
  public perapp: boolean = false;
  @JsonProperty('prbu', String)
  public prbu: string = '';
  @JsonProperty('ragionesoc', String)
  public ragionesoc: string = '';
  @JsonProperty('rev', String)
  public rev: string = '';
  @JsonProperty('saldo', Number)
  public saldo: number = 0;
  @JsonProperty('scorta', Boolean)
  public scorta: boolean = false;
  @JsonProperty('smaltim', Boolean)
  public smaltim: boolean = false;
  @JsonProperty('solosicuro', Boolean)
  public solosicuro: boolean = false;
  @JsonProperty('stato', String)
  public stato: string = '';
  @JsonProperty('statoKey', String)
  public statoKey: string = '';
  @JsonProperty('consegnaKey', String)
  public consegnaKey: string = '';
  @JsonProperty('totale', Number)
  public totale: number = 0;
  @JsonProperty('comuneDep', String)
  public comuneDep: string = "";
  @JsonProperty('indDep', String)
  public indDep: string = "";
  @JsonProperty('nomeDep', String)
  public nomeDep: string = "";
  @JsonProperty('orarioDep', String)
  public orarioDep: string = "";
  @JsonProperty('dettagli', [Detail])
  public dettagli: Detail[] = [];
  @JsonProperty('buonispesa', [Voucher])
  public buonispesa: Voucher[] = [];
  @JsonProperty('navetta', Boolean)
  public navetta: boolean = false;
  @JsonProperty('isPadre', Boolean)
  public isPadre: boolean = false;
  @JsonProperty('comunePv', String)
  public comunePv: string = "";
  @JsonProperty('indPv', String)
  public indPv: string = "";
  @JsonProperty('nomePv', String)
  public nomePv: string = "";
  @JsonProperty('orarioPv', String)
  public orarioPv: string = "";
  @JsonProperty('latitudePv', String)
  public latitudePv: string = "";
  @JsonProperty('longitudePv', String)
  public longitudePv: string = "";
  @JsonProperty('latitudeDep', String)
  public latitudeDep: string = "";
  @JsonProperty('longitudeDep', String)
  public longitudeDep: string = "";
  @JsonProperty('idraulico', Boolean)
  public idraulico: boolean = false;
  @JsonProperty('isRiprogrammazione', Boolean)
  public isRiprogrammazione: boolean = false;
  @JsonProperty('isRevisione', Boolean)
  public isRevisione: boolean = false;
  @JsonProperty('isSistemazione', Boolean)
  public isSistemazione: boolean = false;
  @JsonProperty('isCostiGiacenza', Boolean)
  public isCostiGiacenza: boolean = false;
  @JsonProperty('isRitiroMerce', Boolean)
  public isRitiroMerce: boolean = false;
  @JsonProperty('cambioart', Boolean)
  public cambioart: boolean = false;
  @JsonProperty('catesterno', Boolean)
  public catesterno: boolean = false;
  @JsonProperty('fuorizona', Boolean)
  public fuorizona: boolean = false;
  @JsonProperty('isGeometra', Boolean)
  public isGeometra: boolean = false;
  @JsonProperty('isDolceCasa', Boolean)
  public isDolceCasa: boolean = false;
  @JsonProperty('isDropOff', Boolean)
  public isDropOff: boolean = false;
  @JsonProperty('isCatanzaroDep', Boolean)
  public isCatanzaroDep: boolean = false;
  @JsonProperty('isAddebitoTrasporto', Boolean)
  public isAddebitoTrasporto: boolean = false;
  @JsonProperty('isTraghetto', Boolean)
  public isTraghetto: boolean = false;
  @JsonProperty('consdes', String)
  public consdes: string = "";
  @JsonProperty('blockReservation', Boolean)
  public blockReservation: boolean = false;
  @JsonProperty('nazcli', String)
  public nazcli: string = '';
  @JsonProperty('nazione', String)
  public nazione: string = '';
  @JsonProperty('impgeo', Number)
  public impgeo: number = 0;
}
