import { UrlService } from "./url.service";
import { SignupParams } from "src/entities/SignupParams";
import { Router } from "@angular/router";
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { HeadersService } from "./headers.service";
import { EncryptionService } from "./encryption.service";
import { BehaviorSubject, catchError, map, Observable, of, switchMap } from "rxjs";

@Injectable({
  providedIn: 'root',
})
export class SignupService {
  signup$: Observable<any>;
  private cbkSignup$: BehaviorSubject<SignupParams>;
  headers: HttpHeaders;

  constructor(
    private _cryptoService: EncryptionService,
    private _headersService: HeadersService,
    private _urlService: UrlService,
    private http: HttpClient,
    private recaptchaV3Service: ReCaptchaV3Service,
    private route: Router,
  ) {
    this.headers = this._headersService.getHeaders();
    this.cbkSignup$ = new BehaviorSubject<SignupParams>({ email: '', password: '', firstname: '', lastname: '', marketing: false});

      /**
      * Signup Login
      * !!ATTENZIONE return status!!
      * 200 => success
      * 450 => wrong password
      * 451 => exist email
      * 455 => undefined
      * @param {Object} param : { email:string, password:string, firstname:string, lastname:string }
      * @param {string} pwd
      * @return {User} Return a object user and set islogged
      * @public
      */
     let parameter: SignupParams;
    this.signup$ = this.cbkSignup$.pipe(
      switchMap((params) => {
        parameter = params;
        return this.recaptchaV3Service.execute('login');
      }),
      map((token: string) => {
        if (!token) this.route.navigate(['/login']);
        return parameter;
      }),
      switchMap((params) => {
        if (!params.email && !params.password && !params.firstname && !params.lastname) return of('');
        const url = this._urlService.getUrl('signup');

        const pwdDecrypt = this._cryptoService.encrypt(params.password);
        return this.http
        .post(url, {
          email: params.email,
          password: pwdDecrypt,
          firstname: params.firstname,
          lastname: params.lastname,
          marketing: params.marketing,
        }, {
          headers: this.headers,
          observe: `body`,
          responseType: 'text'
        })
      }),
      map((response): any => {
        const parseRes = JSON.parse(response);
        if (typeof(parseRes) == 'object'){
          if (parseRes[0] == 400)  return {
            data: [],
            status: 455
          };

          if (parseRes[0] == 200) return {
            data: response,
            status: 200
          }
        }
      }),
      catchError((e) => {
        const err = JSON.parse(e.error);
        if (e instanceof HttpErrorResponse && err.code == 400) {
          if ((err.message).includes('password')) return of ({
            data: [],
            status: 450
          });
          if ((err.message).includes('email'))return of({
            data: [],
            status: 451
          });
        }
        return of({
          data: [],
          status: 455
        });
      })
    )
  }

  cbkSignup(params: SignupParams): any {
    this.cbkSignup$.next(params);
    return this.signup$;
  }
}
