import { Component, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LOGO } from 'src/config/constats';
import { REG_PWD } from 'src/config/constats';
import { RouteService } from 'src/app/service/route.service';
import { StorageService } from 'src/app/service/storage.service';
import { TranslateService } from '@ngx-translate/core';
import { WaitlistFormResponse } from 'src/entities/waitlist';
import { WaitlistService } from 'src/app/service/waitlist.service';
import { GenericModalService } from 'src/app/service/modal/genericModal.service';


interface DataForm {
  shop: string | undefined;
  department: string | undefined;
  firstname?: string;
  lastname?: string;
  email?: string;
}

@Component({
  selector: 'app-waiting-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent {
  logoUrl: string = LOGO;
  formData!: FormGroup;
  show_eye: Array<boolean> = [false, false];
  show_button: Array<boolean> = [false, false];
  isGuest: boolean = false;

  constructor(
    private _fb: FormBuilder,
    private _genericModalService: GenericModalService,
    private _storageService: StorageService,
    private _translate: TranslateService,
    private _waitlistService: WaitlistService,
    private viewContainerRef: ViewContainerRef,
    public _routeService: RouteService,
  ) {
    const data = this._waitlistService.getDataWl();
    const passwordValidation = this._waitlistService.getGuest() ? ['', [
      Validators.pattern(REG_PWD)]] : [''];
    const privacyValidation = this._waitlistService.getGuest() ? [false, [Validators.requiredTrue]] : [false];

    if (!data) return;
    this.formData = this._fb.group({
      firstname: ['', [Validators.required]],
      lastname: ['', [Validators.required]],
      phone: [''],
      email: ['', [Validators.required, Validators.email]],
      newsletter: [false],
      privacy: privacyValidation,
      password: passwordValidation,
      shop: [{ value: data.shop || '', disabled: true }],
      department: [{ value: data.department, disabled: true }]
    });
  }

  ngOnInit() {
    this.isGuest = this._waitlistService.getGuest();
    this._waitlistService.getDataObservable().subscribe((newData) => {
      if (newData && newData.token === '') {
        return;
        // alert('Token scaduto! si prega di scansionare nuovamente il qr code');
      }

      let dataForm:DataForm = {
        shop: newData?.shop,
        department: newData?.department
      };
      
      if (!this.isGuest) {
        const personalData = this._storageService.getItem('personaldata');
        if (personalData) {
          const { firstname, lastname, email } = personalData;

          dataForm = {
            firstname: firstname || '',
            lastname: lastname || '',
            email: email || '',
            ...dataForm,
          };
        }
      }
      this.formData.patchValue(dataForm);
    });
  }

  showPassword(n: number) {
    this.show_button[n] = !this.show_button[n];
    this.show_eye[n] = !this.show_eye[n];
  }

  getErrorMessage(controlName: string): string {
    const control = this.formData.get(controlName);
    if (control?.hasError('required')) {
      return this._translate.instant('errors.fieldRequired');
    }
    if (control?.hasError('email')) {
      return this._translate.instant('errors.email');
    }
    return '';
  }

  onSubmit() {
    if (this.formData && this.formData.valid) {
      this._waitlistService.sendDataForm(this.formData).subscribe((response: WaitlistFormResponse) => {
        if (!response || response.code != 200) {
          if (response.code === 400) {
            alert("Questa mail è presente nei nostri sistemi.");
            // this.openModalError(new MouseEvent('click'), "Questa mail è presente nei nostri sistemi.")
          } else {
            alert(response.message);
          }
          this._routeService.goTo('lista-attesa');
          this._storageService.removeItem(['isGuest', 'dataWl']);
        } else {
          this.formData.reset();
          this._routeService.goTo('lista-attesa-check');
        }
      });
    }
  }

  openModalError(e?: Event, msg:string = '') {
    e?.preventDefault();
    this._genericModalService.setRootViewContainerRef(this.viewContainerRef);
    this._genericModalService.addDynamicComponent('generic', msg);
  }
}
