export const CANALE: {[key: string]: string} = {
  N: 'Punto Vendita',
  E: 'E-commerce',
  T: 'Televendita'
};

export const CONSEGNA: {[key: string]: string} = {
  P: 'Trasporto e Montaggio',
  R: 'Ritiro Cliente',
  S: 'Sotto Casa Tua',
  C: 'Corriere Nazionale',
  N: 'Navetta'
};

export const ACCEPTED_STATE = [
  'sospeso',        // 0
  'misure',         // 1
  'finanziaria',    // 2
  'acconto',        // 3
  'irreperibile',   // 4
  'annullato',      // 5
  'consegnato',     // 6
  'consegna',       // 7
  'appuntamento',   // 8
  'pronto',         // 9
  'lavorazione'     // 10
];

export const STATE: {[key: string]: string} = {
  sospeso: 'ordine sospeso',
  misure: 'rilievo misure in corso',
  finanziaria: 'in approvazione finanziaria',
  acconto: 'acconto o saldo da pervenire',
  irreperibile: 'cliente non reperibile',
  annullato: 'annullato',
  consegnato: 'consegnato',
  consegna: 'in consegna il ',
  appuntamento: 'appuntamento il ',
  pronto: 'pronto per l\'appuntamento',
  lavorazione: 'in lavorazione',
};

export const CONSEGNA_GOLD: Array<string> = [
  "Non attiva",
  "08:00 - 09:00",
  "08:30 - 09:30",
  "09:00 - 10:00",
  "09:30 - 10:30",
  "10:00 - 11:00",
  "10:30 - 11:30",
  "11:00 - 12:00",
  "11:30 - 12:30",
  "12:00 - 13:00",
  "12:30 - 13:30",
  "13:00 - 14:00",
  "13:30 - 14:30",
  "14:00 - 15:00",
  "14:30 - 15:30",
  "15:00 - 16:00",
  "15:30 - 16:30",
  "16:00 - 17:00",
  "16:30 - 17:30",
  "17:00 - 18:00",
  "17:30 - 18:30",
  "18:00 - 19:00",
  "18:30 - 19:30"
];
export const LOGO: string = 'common.logo';
export const LOGO_MOBILE: string = 'common.logo_mobile';

/** NGB Date Picker */
const currentYear: number = new Date().getFullYear();
export const DISABLE_DATE_NGB_DATE_PICKER = {
  disable: [7],
  disabledDates: [
    { year: Number(currentYear), month: 1, day: 1 },
    { year: Number(currentYear), month: 1, day: 6 },
    { year: Number(currentYear), month: 4, day: 25 },
    { year: Number(currentYear), month: 5, day: 1 },
    { year: Number(currentYear), month: 6, day: 2 },
    { year: Number(currentYear), month: 8, day: 15 },
    { year: Number(currentYear), month: 11, day: 1 },
    { year: Number(currentYear), month: 12, day: 8 },
    { year: Number(currentYear), month: 12, day: 25 },
    { year: Number(currentYear), month: 12, day: 26 },
  ]
};

export const DES_ADDEBITO_TRASPORTO = 'addebito per trasporto e montaggio';
export const DES_ADDEBITO_TRASPORTO_2 = 'trasporto';
export const DES_COSTI_GIACENZA = 'dep merce presso il nostro magazzino';
export const DES_DOLCE_CASA = 'dolce casa';
export const DES_GEOMETRA = 'geometra';
export const DES_NAVETTA = 'servizio navetta';
export const DES_RIPROGRMMAZIONE = 'CORRISP.X RIPROGR.CONSEGNA';
export const DES_RITIRO_MERCE = 'ritiro merce';
export const DES_SISTEMAZIONE = 'sistemazione';
export const DES_SISTEMAZIONE_2 = 'montaggio';
export const DES_VIAGGIO = 'viaggio';
export const NAVETTA = 'navetta';


export const REG_SDOPPIATO:RegExp = /^[1-9]*$/;
export const REG_REVISONE:RegExp = /^[a-zA-Z]*$/;
export const REG_PADRE:RegExp = /^[a-zA-Z1-9]*$/;

export const REG_PWD:string = "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@.,;:()+=£|<>$%^&*-]).{8,}$";
