
import localeIt from '@angular/common/locales/it';
import localeItExtra from '@angular/common/locales/extra/it';
import { AddressComponent } from './address/address.component';
import { AddressDetailComponent } from './address-detail/address-detail.component';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AssistanceComponent } from './assistance/assistance.component';
import { BannerBottomComponent } from "./banner-bottom/banner-bottom.component";
import { BannerReservationComponent } from './banner-reservation/banner-reservation.component';
import { BarcodeComponent } from './modal/barcode/barcode.component';
import { BarcodeService } from './service/modal/barcode.service';
import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, LOCALE_ID, isDevMode } from '@angular/core';
import { CambioComponent } from './serviziMC/cambio/cambio.component';
import { CardProductComponent } from './card-product/card-product.component';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { CatEsternoComponent } from './serviziMC/cat-esterno/cat-esterno.component';
import { CheckTurnComponent } from './waiting-list/check-turn/check-turn.component';
import { CodeControlComponent } from './modal/code-control/code-control.component';
import { CodeGuestBoxComponent } from './code-guest-box/code-guest-box.component';
import { ContainerServiziMcComponent } from './serviziMC/container-servizi-mc/container-servizi-mc.component';
import { CostiGiacenzaComponent } from './serviziMC/costi-giacenza/costi-giacenza.component';
import { CostiViaggioComponent } from './serviziMC/costi-viaggio/costi-viaggio.component';
import { CreateAccountBoxComponent } from './create-account-box/create-account-box.component';
import { CreateAppointmentComponent } from './buttons/appointment/create/create-appointment.component';
import { CustomCookieService } from "./service/customCookie.service";
import { CustomModalComponent } from './custom-modal/custom-modal.component';
import { DashboardAdminComponent } from './dashboard-admin/dashboard-admin.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DolceCasaComponent } from './serviziMC/dolce-casa/dolce-casa.component';
import { DynamicModalService } from './service/modal/dynamicModal.service';
import { EditAppointmentComponent } from './buttons/appointment/edit/edit-appointment.component';
import { ErrorFormComponent } from './error-form/error-form.component';
import { FilterProductAssPipe } from '../pipe/filter-product-ass.pipe';
import { FilterProductPipe } from '../pipe/filter-product.pipe';
import { FooterComponent } from "./footer/footer.component";
import { FormComponent } from './waiting-list/form/form.component'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FunctionDisabledComponent } from './buttons/function-disabled/function-disabled.component';
import { FunzioneDisabilitataComponent } from './modal/funzione-disabilitata/funzione-disabilitata.component';
import { FuoriZonaComponent } from './serviziMC/fuori-zona/fuori-zona.component';
import { GOOGLE_APP_ID, FACEBOOK_APP_ID } from './../config/social';
import { GenericModalService } from './service/modal/genericModal.service';
import { GeometraComponent } from './serviziMC/geometra/geometra.component';
import { GestioneArticoloComponent } from './serviziMC/gestione-articolo/gestione-articolo.component';
import { GuestOrdersComponent } from "./guest-orders/guest-orders.component";
import { HeaderComponent } from './header/header.component';
import { HelpSectionComponent } from './help-section/help-section.component';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import { IdraulicoComponent } from './serviziMC/idraulico/idraulico.component';
import { LoaderComponent } from './loader/loader.component';
import { LoginAdminComponent } from './login-admin/login-admin.component';
import { LoginAdminFailComponent } from './modal/login-admin-fail/login-admin-fail.component';
import { LoginAdminFailService } from './service/modal/loginAdminFail.service';
import { LoginBoxAdminComponent } from './login-box-admin/login-box-admin.component';
import { LoginBoxComponent } from './login-box/login-box.component';
import { LoginComponent as LoginWLComponent } from './waiting-list/login/login.component';
import { LoginComponent } from './login/login.component';
import { MenuComponent } from './menu/menu.component';
import { MoreInfoComponent } from './buttons/more-info/more-info.component';
import { MoreInfoModalComponent } from './modal/more-info/more-info-modal.component';
import { NetworkInterceptor } from './network.interceptor';
import { NewsletterComponent } from './newsletter/newsletter.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { OrderDeliveryComponent } from './order-delivery/order-delivery.component';
import { OrderDetailAddressComponent } from './order-detail-address/order-detail-address.component';
import { OrderDetailComponent } from './order-detail/order-detail.component';
import { OrderTableComponent } from './order-table/order-table.component';
import { OrdersActionsComponent } from './orders-actions/orders-actions.component';
import { OrdersComponent } from './orders/orders.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ParteOrdineComponent } from './serviziMC/parte-ordine/parte-ordine.component';
import { PayByLinkAdminComponent } from './modal/pay-by-link-admin/pay-by-link-admin.component';
import { PayByLinkAdminService } from './service/modal/payByLinkAdmin.service';
import { PayByLinkComponent } from './modal/pay-by-link/pay-by-link.component';
import { PayByLinkService } from './service/modal/payByLink.service';
import { PaymentModalComponent } from './payment-modal/payment-modal.component';
import { PaymentModalService } from './service/modal/paymentModal.service';
import { PersonalDataComponent } from './personal-data/personal-data.component';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { ReservationComponent } from './reservation/reservation.component';
import { ReservationService } from './service/reservation.service';
import { RiprogrammazioneComponent } from './serviziMC/riprogrammazione/riprogrammazione.component';
import { RitiroMerceComponent } from './serviziMC/ritiroMerce/ritiroMerce.component';
import { RouteService } from './service/route.service';
import { ServiceModalComponent } from './service-modal/service-modal.component';
import { ServiceProductModalService } from './service/serviceProductModal.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ShippingModalComponent } from './shipping-modal/shipping-modal.component';
import { ShippingModalService } from './service/modal/shippingModal.service';
import { SistemazioneComponent } from './serviziMC/sistemazione/sistemazione.component';
import { SmaltimentoComponent } from './serviziMC/smaltimento/smaltimento.component';
import { SocialLoginModule, GoogleLoginProvider, FacebookLoginProvider, SocialAuthServiceConfig, GoogleSigninButtonModule } from '@abacritt/angularx-social-login';
import { TranslateHttpLoader} from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import { TrasportoMontaggioModalComponent } from './trasporto-montaggio-modal/trasporto-montaggio-modal.component';
import { TrasportoMontaggioModalService } from './service/modal/trasportoMontaggioModal.service';
import { VouchersComponent } from './vouchers/vouchers.component';
import { WrongGuidComponent } from './modal/wrong-guid/wrong-guid.component';
import { environment } from './../environments/environment';
import { registerLocaleData } from '@angular/common';

registerLocaleData(localeIt, 'it-IT', localeItExtra);
@NgModule({
  declarations: [
    AddressComponent,
    AddressDetailComponent,
    AppComponent,
    AssistanceComponent,
    BannerBottomComponent,
    BannerReservationComponent,
    BarcodeComponent,
    CambioComponent,
    CardProductComponent,
    CatEsternoComponent,
    CheckTurnComponent,
    CodeControlComponent,
    CodeGuestBoxComponent,
    ContainerServiziMcComponent,
    CostiGiacenzaComponent,
    CostiViaggioComponent,
    CreateAccountBoxComponent,
    CreateAppointmentComponent,
    CustomModalComponent,
    DashboardAdminComponent,
    DashboardComponent,
    DolceCasaComponent,
    EditAppointmentComponent,
    ErrorFormComponent,
    FilterProductAssPipe,
    FilterProductPipe,
    FooterComponent,
    FormComponent,
    FunctionDisabledComponent,
    FunzioneDisabilitataComponent,
    FuoriZonaComponent,
    GeometraComponent,
    GestioneArticoloComponent,
    GuestOrdersComponent,
    HeaderComponent,
    HelpSectionComponent,
    IdraulicoComponent,
    LoaderComponent,
    LoginAdminComponent,
    LoginAdminFailComponent,
    LoginBoxAdminComponent,
    LoginBoxComponent,
    LoginComponent,
    LoginWLComponent,
    MenuComponent,
    MoreInfoComponent,
    MoreInfoComponent,
    MoreInfoModalComponent,
    NewsletterComponent,
    OrderDeliveryComponent,
    OrderDetailAddressComponent,
    OrderDetailComponent,
    OrderTableComponent,
    OrdersActionsComponent,
    OrdersComponent,
    PageNotFoundComponent,
    ParteOrdineComponent,
    PayByLinkAdminComponent,
    PayByLinkComponent,
    PaymentModalComponent,
    PersonalDataComponent,
    ReservationComponent,
    RiprogrammazioneComponent,
    RitiroMerceComponent,
    ServiceModalComponent,
    ShippingModalComponent,
    SistemazioneComponent,
    SmaltimentoComponent,
    TrasportoMontaggioModalComponent,
    VouchersComponent,
    WrongGuidComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    CarouselModule.forRoot(),
    FormsModule,
    GoogleSigninButtonModule,
    HttpClientModule,
    NgbModule,
    ReactiveFormsModule,
    RecaptchaV3Module,
    SocialLoginModule,
    TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient]
        },
        defaultLanguage: 'it',
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
  ],
  providers: [
    BarcodeService,
    CustomCookieService,
    DynamicModalService,
    GenericModalService,
    LoginAdminFailService,
    PayByLinkAdminService,
    PayByLinkService,
    PaymentModalService,
    ReservationService,
    RouteService,
    ServiceProductModalService,
    ShippingModalService,
    TranslateService,
    TrasportoMontaggioModalService,
    {
      provide: LOCALE_ID,
      useValue: 'it-IT',
    },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              GOOGLE_APP_ID
            )
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(FACEBOOK_APP_ID)
          }
        ],
        onError: (err) => {
          console.error(err);
        }
      } as SocialAuthServiceConfig,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NetworkInterceptor,
      multi: true,
    },
    {
      provide: 'googleTagManagerId',
      useValue: environment.gmtCode
    },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.recaptcha.siteKey,
    }
  ],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class AppModule { }

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}
