<div class="check-turn-container">
  <div class="logo-section">
    <img src="{{ logoUrl | translate }}" alt="Mondo Convenienza Logo" class="mb-md-5 mb-sm-4 mx-auto d-block" />
  </div>
  <div class="centered-div">
    <ng-template [ngIf]="isYourTurn" [ngIfElse]="waitList">
      <div class="turn-content">
        <div class="icon">
          <img [src]="assetImg['success']" alt="Check Icon" />
        </div>
        <h1>{{"waitingList.checkTurn.titleSuccess" | translate }}</h1>
        <p>
         {{ textSuccess }}
        </p>
      </div>
    </ng-template>
    <ng-template #waitList>
      <div class="status-content">
        <div class="icon">
          <img [src]="assetImg['walk']" alt="Group Icon" />
        </div>
        <h1>{{"waitingList.checkTurn.titleWait" | translate }}</h1>
        <p>{{"waitingList.checkTurn.subtitleWait" | translate }}</p>
      </div>
    </ng-template>
  </div>
</div>