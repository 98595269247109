export const whitelistDomain: Array<string> = [
    'admin',
    'login',
    'signup',
    'dashboard',
    'search',
    'orders',
    'personalData',
    'newsletter',
    'address',
    'addressDetail',
    'orderDetail',
    'reservation',
    'vouchers',
    'lista-attesa'
  ];