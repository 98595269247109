<div id="box_form" class="shadow bg-white">
  <h1 class="text-center mb-md-1 mb-sm-3">{{ "login.loginAccount" | translate }}<br>Mondo Convenienza</h1>
  <p class="text-center mb-md-2 mb-2" style="font-size: 14px;">{{ "login.loginSubTitle" | translate }}</p>
  <form [formGroup]="loginDataForm" class="mb-4">
    <div class="row mb-md-3 mb-sm-3">
      <div class="form-loginDataFormGroup w-100">
        <input 
          class="form-control" 
          id="email" 
          type="text" 
          formControlName="email" 
          placeholder="Email" 
          autocomplete="email"/>
        <app-error-form [fieldControl]="loginDataForm.controls['email']" codeError="errors.email"></app-error-form>
      </div>
    </div>
    <div class="mc-password-field input-group mx-0">
      <input 
        class="form-control" 
        id="password" 
        [type]="show_button ? 'text' : 'password'" formControlName="password"
        placeholder="Password"
        autocomplete="current-password" />
      <i class="input-group-text" [class]="show_eye ? 'bi bi-eye' : 'bi bi-eye-slash'" (click)="showPassword()"></i>

    </div>
    <div class="mb-md-2 mb-sm-3">
      <app-error-form [fieldControl]="loginDataForm.controls['password']" codeError="errors.passwordReq">
      </app-error-form>
    </div>
    <div class="row mx-0 mb-3">
      <a class="par_2 btn-link fw-regular p-0" href="http://www.mondoconv.it/account/forgotpassword"
        routerLinkActive="router-link-active" target="_blank">{{ "login.ForgottenPassword" | translate }}</a>
    </div>
    <button type="submit" class="btn btn-primary w-100 mb-0" [disabled]="loginDataForm.invalid" (click)="onSubmit()">
      {{ "buttons.access" | translate }}
    </button>
  </form>
  <p class="text-alternative-login par_2 text-center mb-3">{{ "login.txtAlternativeLogin" | translate }}</p>
  <div class="row m-0 mb-md-4 mb-sm-4 justify-content-sm-between">
    <div class="col-6 pl-0 login-button">
      <button type="submit" (click)="signInWithFB()"
        class="btn btn-facebook border border-dark text-black w-100 mb-0 text-capitalize fw-regular d-flex align-items-center justify-content-center">
        <i class="fa fa-facebook me-2" aria-hidden="true"></i> {{ "login.loginWFacebook" | translate }}
      </button>
    </div>
    <div class="col-6 pr-0 login-button">
      <div *ngIf="!isMobile; else buttonMobile">
        <asl-google-signin-button
          type="standard"
          shape="pill"
          size="large"
          theme="outline"
          logo_alignment="left"
          width="220"
          text="signin_with"
          class="g_id_signin">
        </asl-google-signin-button>
      </div>
      <ng-template #buttonMobile>
        <asl-google-signin-button
          type="icon"
          shape="circle"
          theme="outline"
          text="signin_with"
          size="large"
          class="g_id_signin">
        </asl-google-signin-button>
      </ng-template>

    </div>
  </div>
  <h3 class="h1_title text-center mb-3">{{ "login.txtNewClient" | translate }}</h3>
  <button type="submit" class="btn btn-primary w-100 mb-4" (click)="goToSignUp()">{{ "buttons.signUp" | translate
    }}</button>
  <p class="par_2 text-center mb-1">
    {{ "login.desNewClient1" | translate }}
  </p>
  <button
    class="btn btn-blue-reverse w-100 mb-4"
    name="guid"
    (click)="setLoginBox(false)">
    {{ "login.desNewClient2" | translate | uppercase }}
  </button>
</div>
<app-custom-modal *ngIf="showModal" (closeModal)="closeModal()" modalClass="mobile-height">
  <ng-container>
    <div class="Modal-container">
      <h3 class="mb-3 text-center">{{ titleModal | translate }}</h3>
      <p class="text-center">{{ descModal | translate }}</p>
    </div>
  </ng-container>
</app-custom-modal>

<app-loader *ngIf="loader"></app-loader>
