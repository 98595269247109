import { ComponentFactoryResolver, ComponentRef, Injectable, ViewContainerRef } from "@angular/core";
import { ServiceModalComponent } from "../../service-modal/service-modal.component";
import { TranslateService } from "@ngx-translate/core";

@Injectable()
export class GenericModalService {
  private rootViewContainer!: ViewContainerRef;

  constructor(
    private factoryResolver: ComponentFactoryResolver,
    private _translate: TranslateService
  ) {
    this.factoryResolver = factoryResolver;
  }

  setRootViewContainerRef(viewContainerRef: ViewContainerRef) {
    this.rootViewContainer = viewContainerRef;
  }

  addDynamicComponent(type: string, msg:string = '') {
    let title;
    let desc;

    switch (type) {
      case 'offline':
        title = this._translate.instant('offline.txt');
        desc = this._translate.instant('offline.desc');
        break;
      case 'waitlist':
        title = this._translate.instant('createAccount.modal.title');
        desc = msg;
        break;
    }

    const factory = this.factoryResolver.resolveComponentFactory(ServiceModalComponent);
    const component = factory.create(this.rootViewContainer.parentInjector);
    component.instance.type = type;
    component.instance.title = title;
    component.instance.desc = desc;
    // Subscribe to the closeModal event and destroy the component
    component.instance.closeModal.subscribe(() => this.removeDynamicComponent(component));

    this.rootViewContainer.insert(component.hostView);
  }

  removeDynamicComponent(component: ComponentRef<any>) {
    component.destroy();
  }
}
