import { AssetImage } from 'src/entities/assetImage';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DeviceService } from '../service/device.service';
import { Order } from 'src/entities/order';
import { PAYMENT } from 'src/config/images';

@Component({
  selector: 'app-payment-modal',
  templateUrl: './payment-modal.component.html',
  styleUrls: ['./payment-modal.component.scss'],
})
export class PaymentModalComponent implements OnInit {
  @Output() closeModal = new EventEmitter<any>();
  @Input() order!: Order;
  @Input() saldo!: number;

  _template: string = '';
  acceptedMethod: string[] = [];
  assetImg:AssetImage= PAYMENT;
  isMobile: boolean = false;

  constructor(
    private _deviceService: DeviceService
  )  {
    this.isMobile = this._deviceService._isMobile.getValue();
  }
  ngOnInit() {
    if (this.order.consegnaKey === 'R') {
      this._template += this.saldo < 2000 ? 't' : 'f';
      this._template += this.order.solosicuro
        ? 'f'
        : this.order.assban
        ? 't'
        : 'f';
    }
    if (this.order.consegnaKey === 'P' || this.order.consegnaKey === 'S') {
      this._template +=
        this.saldo < 2000 && !this.order.solosicuro && !this.order.nocash
          ? 't'
          : 'f';
      this._template += this.order.solosicuro
        ? 'f'
        : this.order.assban
        ? 't'
        : 'f';
    }
    switch (this._template) {
      case 'ff':
        this.acceptedMethod = [
          'assegno circolare',
          'bonifico',
          'carta di credito',
        ];
        break;
      case 'ft':
        this.acceptedMethod = [
          'assegno circolare',
          'assegno bancario',
          'bonifico',
          'carta di credito',
        ];
        break;
      case 'tf':
        this.acceptedMethod = [
          'pagamento in contanti',
          'assegno circolare',
          'bonifico',
          'carta di credito',
        ];
        break;
      case 'tt':
        this.acceptedMethod = [
          'pagamento in contanti',
          'assegno circolare',
          'assegno bancario',
          'bonifico',
          'carta di credito',
        ];
        break;
      default:
        this.acceptedMethod = [];
        break;
    }
  }

  close(event: any) {
    this.closeModal.emit(event);
  }
}
