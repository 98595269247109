import { Injectable } from "@angular/core";
import { BehaviorSubject, catchError, Observable, of, shareReplay, switchMap, takeWhile, tap, throwError, timer } from "rxjs";
import { WaitlistForm, WaitlistFormResponse, WaitlistParams } from "src/entities/waitlist";
import { UrlService } from "./url.service";
import { FormGroup } from "@angular/forms";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { StorageService } from "./storage.service";
import { EncryptionService } from "./encryption.service";
@Injectable({
  providedIn: 'root',
})


export class WaitlistService {
  private waitlist = new BehaviorSubject<WaitlistParams | null>(null);
  private guest = new BehaviorSubject<boolean>(false);
  private isRedirect = new BehaviorSubject<boolean>(false);

  public readonly waitlist$ = this.waitlist.asObservable();
  public readonly guest$ = this.guest.asObservable();
  public readonly isRedirect$ = this.isRedirect.asObservable();

  constructor(
    private _urlService: UrlService,
    private http: HttpClient,
    private _storageService: StorageService,
    private _cryptoService: EncryptionService
  ) {
    this.loadDataFromLocalStorage();
  }

  setDataWl(v: WaitlistParams | null) {
    this.waitlist.next(v);
  }

  getDataWl(): WaitlistParams | null {
    return this.waitlist.getValue();
  }

  setGuest(v: boolean) {
    this.guest.next(v);
    this._storageService.setItem('isGuest', v.toString(), 3600);
  }

  getGuest(): boolean {
    const storedValue = this._storageService.getItem('isGuest');
    if (storedValue !== null) {
      const isGuest = storedValue === 'true';
      this.guest.next(isGuest);
      return isGuest;
    }
    
    return this.guest.getValue();
  }

  setIsRedirect(v: boolean) {
    this.isRedirect.next(v);
  }

  getDataObservable() {
    return this.waitlist.asObservable();
  }

  loadDataFromLocalStorage(): void {
    const rawData = this._storageService.getItem('dataWl');
    let data: WaitlistParams | null = null;
    if (rawData) {
      try {
        if (
          typeof rawData.token === 'string' &&
          typeof rawData.shop === 'string' &&
          typeof rawData.department === 'string'
        ) {
          data = rawData as WaitlistParams;
          this.setDataWl(data);
        } else {
          this.setDataWl(null);
          console.warn('Data in localStorage does not match WaitlistParams structure');
        }
      } catch (error) {
        console.error('Error parsing JSON from localStorage:', error);
      }
    }
  }

  /**
    * Insert form waitlist
    * @param {FormGroup} data
    * @return {WaitlistFormResponse} response
    * @public
    */
  sendDataForm(data: FormGroup<any>): Observable<WaitlistFormResponse> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const defaultParams = this.getDataWl();
    if (!defaultParams || !defaultParams?.token) {
      return of({
        code: 500,
        message: false,
      });
    }

    const payload: WaitlistForm = {
      name: data.value.firstname,
      surname: data.value.lastname,
      email: data.value.email,
      telephone: data.value.phone,
      password: data.value.password !== '' ? this._cryptoService.encrypt(data.value.password) : '',
      newsletter: data.value.newsletter,
      privacy: data.value.privacy,
      token: defaultParams?.token,
    };
    return this.http
      .post<WaitlistFormResponse>(
        this._urlService.getUrl('waitlistInsert'),
        payload,
        {
          headers,
          observe: 'body',
          responseType: 'json',
        }
      )
      .pipe(
        switchMap((response: WaitlistFormResponse) => {
          return of(response);
        }),
        catchError((e: any) => {
          console.error('Error occurred:', e);
          return of({
            code: e.status,
            message: e.error.message,
          } as WaitlistFormResponse);
        })
      );
  }

  pollUntilConditionMet<WaitlistStatusResposne>(
    url: string,
    condition: (response: WaitlistStatusResposne) => boolean,
    intervalMs: number
  ): Observable<WaitlistStatusResposne> {
    return timer(0, intervalMs).pipe(
      switchMap(() => this.http.get<WaitlistStatusResposne>(url).pipe(
        catchError((error) => {
          console.error('Polling error:', error);
          return throwError(() => error);
        })
      )),
      takeWhile((response) => !condition(response), true),
      tap((response) => {
        if (condition(response)) {
          console.log('Condition met, stopping polling:', response);
        }
      }),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }
}