import { AssetImage } from 'src/entities/assetImage';
import { Component, EventEmitter, Output } from '@angular/core';
import { DeviceService } from '../../service/device.service';
import { ORDER_DELETED } from 'src/config/images';
import { RouteService } from 'src/app/service/route.service';
import { UtilityService } from 'src/app/service/utility.service';
import { WHATSAPP_URL } from 'src/config/social';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-more-info-modal',
  templateUrl: './more-info-modal.component.html',
  styleUrls: ['./more-info-modal.component.scss'],
})
export class MoreInfoModalComponent {
  @Output() closeModal = new EventEmitter<any>();

  isMobile: boolean = false;
  assetImg:AssetImage = ORDER_DELETED;

  constructor(
    private _deviceService: DeviceService,
    private _routeService: RouteService,
    private _utilityService: UtilityService,
  )  {
    this.isMobile = this._deviceService._isMobile.getValue();
  }

  close(event: any) {
    this.closeModal.emit(event);
  }

  goToWa() {
      window.open(WHATSAPP_URL, '_blank');
  }

  goToChat() {
    this._utilityService.openChat();
  }

  goToReservation() {
    const url = `${environment.magento.it}appuntamento-venditore`;
    window.open(url, '_blank');
  }

}
