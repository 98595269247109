<div class="Modal" (click)="closeModal.emit(false)">
  <div class="Modal-inner" (click)="$event.stopPropagation()">
    <div class="Modal-content" id="pay-by-link">
      <i class="bi bi-x custom-close" (click)="closeModal.emit(false)"></i>
      <div class="mobileHeader">
        <i class="bi bi-x custom-close" (click)="closeModal.emit(false)"></i>
      </div>
      <div class="Modal-container">
          <div class="Modal-container">
            <!-- modale payByLink -->
            <h3 class="mb-3 text-center">
              {{ "payByLink.txtTitle" | translate }}
            </h3>
            <div class="d-flex justify-content-center flex-row flex-wrap gap-4">
              <div class="box-container" (click)="selectBox('unip')" [ngClass]="{ selectedBox: selectedBox === 'unip' }">
                <img class="bPaymentImg" [src]="assetImg['bonifico']" alt="bonifico"/>
                <p class="text-center paymentTitle">
                  {{ "payByLink.txtBonifico" | translate }}
                </p>
                <p class="text-center paymentTxt">
                  {{ "payByLink.descBonifico" | translate }}
                </p>
              </div>
              <div class="box-container" (click)="selectBox('cc')" [ngClass]="{ selectedBox: selectedBox === 'cc' }">
                <img class="paymentImg" [src]="assetImg['carta']" alt="carta"/>
                <p class="text-center paymentTitle">
                  {{ "payByLink.txtCc" | translate }}
                </p>
                <p class="text-center paymentTxt">
                  {{ "payByLink.descCc" | translate }}
                </p>
              </div>
              <button
                *ngIf="!isAdmin"
                [disabled]="selectedBox === ''"
                class="btn btn-primary w-100 mb-4 btnHeight"
                (click)="sendRequest()"
                style="max-width: 360px">
                {{ "buttons.goToPayment" | translate }}
              </button>
              <button
                *ngIf="isAdmin"
                [disabled]="selectedBox === ''"
                class="btn btn-primary w-100 mb-4 btnHeight"
                (click)="sendRequestAdmin()"
                style="max-width: 360px">
                {{ "buttons.goToPayment" | translate }}
              </button>
              <p class="support" [innerHTML]="'payByLink.support' | translate"></p>
            </div>
          </div>
      </div>
    </div>
  </div>
</div>
