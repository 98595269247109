import { AddressComponent } from './address/address.component';
import { AddressDetailComponent } from './address-detail/address-detail.component';
import { CheckTurnComponent } from './waiting-list/check-turn/check-turn.component';
import { DashboardAdminComponent } from './dashboard-admin/dashboard-admin.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FormComponent } from './waiting-list/form/form.component';
import { GuestOrdersComponent } from "./guest-orders/guest-orders.component";
import { LoginAdminComponent } from './login-admin/login-admin.component';
import { LoginComponent as LoginWLComponent } from './waiting-list/login/login.component';
import { LoginComponent } from './login/login.component';
import { LoginCookieGuard } from "./guard/login-cookie.guard";
import { NewsletterComponent } from './newsletter/newsletter.component';
import { NgModule } from '@angular/core';
import { OrderDetailComponent } from './order-detail/order-detail.component';
import { OrdersComponent } from './orders/orders.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { PersonalDataComponent } from './personal-data/personal-data.component';
import { ReservationComponent } from './reservation/reservation.component';
import { RouteGuard } from './guard/route.guard';
import { RouterModule, Routes } from '@angular/router';
import { VouchersComponent } from './vouchers/vouchers.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  },
  {
    path: 'admin',
    component: LoginAdminComponent
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [LoginCookieGuard]
  },
  {
    path: 'signup',
    component: LoginComponent,
    canActivate: [LoginCookieGuard]
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [RouteGuard]
  },
  {
    path: 'search',
    component: DashboardAdminComponent,
    canActivate: [RouteGuard]
  },
  {
    path: 'orders',
    component: OrdersComponent,
    canActivate: [RouteGuard]
  },
  {
    path: 'orders/:guid',
    component: GuestOrdersComponent
  },
  {
    path: 'personalData',
    component: PersonalDataComponent,
    canActivate: [RouteGuard]
  },
  {
    path: 'newsletter',
    component: NewsletterComponent,
    canActivate: [RouteGuard]
  },
  {
    path: 'address',
    component: AddressComponent,
    canActivate: [RouteGuard]
  },
  {
    path: 'addressDetail/:id',
    component: AddressDetailComponent,
    canActivate: [RouteGuard]
  },
  {
    path: 'orderDetail/:orderId',
    component: OrderDetailComponent,
    canActivate: [RouteGuard]
  },
  {
    path: 'reservation',
    component: ReservationComponent,
    canActivate: [RouteGuard]
  },
  {
    path: 'reservation/:orderId',
    component: ReservationComponent,
    canActivate: [RouteGuard]
  },
  {
    path: 'vouchers',
    component: VouchersComponent,
    canActivate: [RouteGuard]
  },
  {
    path: 'lista-attesa',
    component: LoginWLComponent,
  },
  {
    path: 'lista-attesa-form',
    component: FormComponent,
  },
  {
    path: 'lista-attesa-check',
    component: CheckTurnComponent,
  },
  { 
    path: '**', 
    component: PageNotFoundComponent,
  } 
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
