import { ComponentRef, Injectable, ViewContainerRef } from "@angular/core";
import { FunzioneDisabilitataComponent } from "../../modal/funzione-disabilitata/funzione-disabilitata.component";

@Injectable()
export class FunzioneDisabilitataService {
    private rootViewContainer!: ViewContainerRef;

    constructor() {}

    setRootViewContainerRef(viewContainerRef: ViewContainerRef) {
        this.rootViewContainer = viewContainerRef;
    }
    addDynamicComponent() {
        const component:ComponentRef<FunzioneDisabilitataComponent> = this.rootViewContainer.createComponent(FunzioneDisabilitataComponent);
        component.instance.closeModal.subscribe(() => this.removeDynamicComponent(component));

        this.rootViewContainer.insert(component.hostView);
    }

    removeDynamicComponent(component: ComponentRef<FunzioneDisabilitataComponent>) {
        component.destroy();
    }
}
