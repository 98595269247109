

import { BehaviorSubject, Observable, of, Subject, throwError, catchError, map, retry, switchMap, tap, finalize } from "rxjs";
import { DomSanitizer } from '@angular/platform-browser';
import { GuestService } from "./guest.service";
import { HeadersService } from "./headers.service";
import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { LoadingService } from "./loading.service";
import { Order } from "src/entities/order";
import { OrdiniUtiltyService } from "./ordiniUtility.service";
import { UrlService } from "./url.service";
import { User } from "src/entities/user";
import { UserService } from "./user.service";

@Injectable({
  providedIn: 'root'
})

export class ApiService {
  guestObs$: Observable<number>;
  isLoggedObs$: Observable<any>;
  orderObs$: Observable<Order>;

  cbkGuest$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  grandTotal$: Subject<number> = new BehaviorSubject<number>(0);
  headers: HttpHeaders;
  isGuest$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isLogged$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  order$: BehaviorSubject<Order> = new BehaviorSubject<Order>(new Order);
  orderIsEmpty$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  orders$: BehaviorSubject<Order[]> = new BehaviorSubject<Order[]>([]);
  shippingCost$: Subject<number> = new BehaviorSubject<number>(0);
  subtotal$: Subject<number> = new BehaviorSubject<number>(0);

  constructor(
    private _guestService: GuestService,
    private _headersService: HeadersService,
    private _loadingService: LoadingService,
    private _ordiniUtility: OrdiniUtiltyService,
    private _urlService: UrlService,
    private _userService: UserService,
    private http: HttpClient,
    private sanitizer: DomSanitizer
  ) {
    this.headers = this._headersService.getHeaders();
    this.orders$.asObservable();
    this.orderObs$ = this.order$.asObservable().pipe(
      tap((order) => {
        let tot = 0;
        let spedizione = 0;
        order.dettagli.forEach((p) => {
          if (p.trasporto) {
            this.shippingCost$.next(p.importo);
            spedizione = p.importo;
          }

          if (p.ccod && !this.isGuest$.getValue()) {
            tot += order.totale;
          } else {
            tot += p.importo;
          }
        })

        const impgeo = order.impgeo | 0;
        const subtotal = tot - spedizione;
        this.shippingCost$.next(spedizione);
        this.subtotal$.next(subtotal);
        this.grandTotal$.next(tot - impgeo);
      }));

    this.isLoggedObs$ = this.isLogged$.pipe(
      switchMap((isLogged) => {
        if (!isLogged) return of('');
        this.isGuest$.next(false);
        return this.getOrdini();
      })
    );

    this.guestObs$ = this.cbkGuest$.pipe(
      switchMap((isGuest) => {
        const guid = this._guestService.getGuid();
        if (!isGuest && !guid) return of(400);
        const cleanGuid: string = guid.trim();
        const url = `${this._urlService.getUrl('orderByGuid')}${cleanGuid}`;
        return this.http
          .get<any>(url, { headers: this._headersService.getHeaders() });
      }),
      map((response: Order[]) => {
        response = this._ordiniUtility.refactoringAttrOrdini({orders: response, isGuest: true});
        response = this._ordiniUtility.setReservationBlockedList(response);

        if (response) {
          this._ordiniUtility.setVoucherObservable();
          this.orders$.next(response);
          this.isGuest$.next(!this.isLogged$.getValue());
          this._guestService.setGuid(this._guestService.getGuid());
          this._loadingService.setHasFinished(true);
          this._loadingService.hide();
          return 200;
        }

        this._guestService.setGuid('');
        return 400;
      }),
      map((response: number) => {
        return response;
      }),
      catchError((e) => {
        this._loadingService.hide();
        return throwError(() => e.error);
      })
    );
  }

  refreshCbkGuest() {
    this.cbkGuest$.next(true);
  }

  getOrdini(emailToSearch: string = '', isAdmin: boolean = false) {
    this._loadingService.show();

    if (isAdmin) {
      const userToSearch = new User;
      userToSearch.email = emailToSearch;
      this._userService.setUser(userToSearch);
    };

    if (!this._userService.user$.getValue().email) return of();

    const url = `${this._urlService.getUrl('order')}${this._userService.user$.getValue().email}`;
    const headers: HttpHeaders = this._headersService.getHeaders(true);
    return this.http
      .get<any>(url, { headers })
      .pipe(
        tap((response: any) => {
          if (!response || response.length === 0) {
            this.orderIsEmpty$.next(true);
            this.orders$.next([]);
          } else {
            const obj: Order[] = this._ordiniUtility.refactoringObjOrders(response);
            this.orders$.next(obj);
          }
        }),
        retry(1),
        finalize(() => {
          this._loadingService.setHasFinished(true);
          this._loadingService.hide();
        }),
        catchError((error: HttpErrorResponse) => {
          this._loadingService.hide();
          console.error(error);
          return throwError(() => new Error('Ops... Qualcosa è andato storto. Riprova piu tardi.'));
        }) 
      )
  }
}
