import { ApiService } from './service/api.service';
import { AutoLoginVsMagento } from './service/autoLoginVsMag.service';
import { Component, OnInit, Version, ViewContainerRef } from '@angular/core';
import { delay, filter, first, map } from 'rxjs/operators';
import { DeviceService } from './service/device.service';
import { GenericModalService } from './service/modal/genericModal.service';
import { LoadingService } from './service/loading.service';
import { LocalizationService } from './service/localization.service';
import { NavigationEnd, Router } from '@angular/router';
import { NewVersionCheckerService } from './service/newVersionChecker.service';
import { Observable, Subject, Subscription, fromEvent, merge, of } from 'rxjs';
import { OrdiniService } from './service/ordini.service';
import { RouteService } from './service/route.service';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from './service/user.service';
import { VouchersService } from './service/vouchers.service';
import { whitelistDomain } from './../config/router';
import { VersionService } from './service/version.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  currentUrl: string = '';
  img: string = '';
  loading$: Observable<boolean>;
  mobileMenuState: boolean = false;
  networkStatus: boolean = false;
  networkStatus$: Subscription = Subscription.EMPTY;
  notValidDomain: boolean = false;
  vouchersCounter$!: Subject<number>;
  version: string = '';
  public isMobile: boolean = false;


  constructor(
    private _genericModalService: GenericModalService,
    public _versionService: VersionService,
    private router: Router,
    private viewContainerRef: ViewContainerRef,
    public _apiService: ApiService,
    public _autoLoginVsMagentoService: AutoLoginVsMagento,
    public _detectorService: DeviceService,
    public _loadingService: LoadingService,
    public _localizationService: LocalizationService,
    public _newVersionChecker: NewVersionCheckerService,
    public _ordiniService: OrdiniService,
    public _routeService: RouteService,
    public _translate: TranslateService,
    public _userService: UserService,
    public _vouchersService: VouchersService,
  ) {
    router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(
      (event: any) => {
        // faccio questo controllo perche da magento torna un url con accodato una string
        this.notValidDomain = !this.isAcceptedDomain(event.url);
        this.currentUrl = ((event.url).includes('?_gl=')) ? '/' : event.url;
      }
    );

    _apiService.isLoggedObs$.subscribe();
    _apiService.orderObs$.subscribe();
    _autoLoginVsMagentoService.autoLoginVsMagento$.subscribe();
    _detectorService.init();
    _localizationService.init();
    _ordiniService.ordini$.subscribe();
    _userService.fullnameObs$.subscribe();
    _vouchersService.vouchersObs$.subscribe();
    this.img = _localizationService.getImageByLang();
    this.isMobile = _detectorService._isMobile.getValue();
    this.loading$ = _loadingService.loading$;
    this.vouchersCounter$ = this._vouchersService.vouchersCounter$;
  }

  ngOnInit(): void {
    this.checkNetworkStatus();
    this._newVersionChecker.init();
    this._versionService.getVersion().subscribe({
      next: (version) => (this.version = version),
      error: (err) => console.error('Error fetching version:', err),
    });
  }

  ngAfterViewInit() {
    if (this.isSafari()) {
      this._translate.get('oldSafariTxt').pipe(first()).subscribe((res: string) => {
        alert(res);
      });

    }
  }

  ngOnDestroy(): void {
    this.networkStatus$.unsubscribe();
  }

  isSafari(): boolean {
    const userAgent = navigator.userAgent;
    const versionSafari = this.getSafariVersion(userAgent);
    const isSafariOldVersion =
      userAgent.includes('Safari') &&
      versionSafari &&
      !userAgent.includes('Chrome') &&
      !userAgent.includes('Chromium');

    return isSafariOldVersion;
  }

  getSafariVersion(userAgent: string): boolean {
    if (/Safari/.test(userAgent) && !/Chrome/.test(userAgent)) {
      const versionMatch = userAgent.match(/Version\/(\d+\.\d+(\.\d+)?)/);
      if (versionMatch) {
        const majorVersion = +((versionMatch[1].split('.'))[0]);
        return majorVersion <= 15;
      }
    }

    return false;
  }


  mobileMenuStateEvent(state: boolean): void {
    this.mobileMenuState = state;
  }

  closeMobileMenu() {
    this.mobileMenuState = false;
  }

  goToOrders() {
    this.router.navigate(['/orders']);
  };

  goToVouchers() {
    this.router.navigate(['/vouchers']);
  };

  checkNetworkStatus() {
    this.networkStatus = navigator.onLine;
    this.networkStatus$ = merge(
      of(null),
      fromEvent(window, 'online'),
      fromEvent(window, 'offline')
    )
      .pipe(
        map(() => navigator.onLine),
        delay(100))
      .subscribe(status => {
        this.networkStatus = status;
        if (!status) this.openModalOffline();
      });
  }

  openModalOffline(e?: Event) {
    e?.preventDefault();
    this._genericModalService.setRootViewContainerRef(this.viewContainerRef);
    this._genericModalService.addDynamicComponent('offline');
  }

  isAcceptedDomain = (url: string): boolean => {
    return whitelistDomain.some(domain => url.includes(domain));
  };
}
