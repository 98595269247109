import { ActivatedRoute, Params } from '@angular/router';
import { ApiService } from '../service/api.service';
import { AppointmentService } from '../service/appointment.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { Component, Injectable, OnInit, ViewContainerRef } from '@angular/core';
import { DeviceService } from '../service/device.service';
import { GtmService } from '../service/gtm.service';
import { GuestService } from '../service/guest.service';
import { NgbCalendar, NgbDate, NgbDateAdapter, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Order } from 'src/entities/order';
import { OrdiniService } from '../service/ordini.service';
import { RouteService } from '../service/route.service';
import { TrasportoMontaggioModalService } from '../service/trasportoMontaggioModal.service';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { UtilityService } from '../service/utility.service';

/**
 * This Service handles how the date is represented in scripts i.e. ngModel.
 */
@Injectable()
export class CustomAdapter extends NgbDateAdapter<string> {
  readonly DELIMITER = '-';

  fromModel(value: string | null): NgbDateStruct | null {
    if (value) {
      const date = value.split(this.DELIMITER);
      return {
        day: parseInt(date[0], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[2], 10),
      };
    }
    return null;
  }

  toModel(date: NgbDateStruct | null): string | null {
    return date ? `` + date.day + this.DELIMITER + date.month + this.DELIMITER + date.year : null;
  }
}

/**
 * This Service handles how the date is rendered and parsed from keyboard i.e. in the bound input field.
 */
@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {
  readonly DELIMITER = '-';

  parse(value: string): NgbDateStruct | null {
    if (value) {
      const date = value.split(this.DELIMITER);
      return {
        day: parseInt(date[0], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[2], 10),
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    return date ? `` + date.day + this.DELIMITER + date.month + this.DELIMITER + date.year : '';
  }
}

@Component({
  selector: 'app-reservation',
  templateUrl: './reservation.component.html',
  styleUrls: ['./reservation.component.scss'],
  providers: [
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
  ],
})
export class ReservationComponent implements OnInit {
  reservationDataForm!: UntypedFormGroup;

  isLogged: boolean = false;
  order: Order = new Order;
  orderId: string = '';

  // Datepicker variable
  outsideDays = 'hidden';
  showWeekNumbers = false;
  navigation = 'arrows';
  firstDayOfWeek = 1;
  minDate!: NgbDateStruct;
  maxDate!: NgbDateStruct;

  grandTotal$: Subject<number>;
  order$: BehaviorSubject<Order>;
  shippingCost$: Subject<number>;
  subtotal$: Subject<number>;
  showModal: boolean = false;
  dateAppFormatterd: string | null = '';

  typePopup: string = "";
  isMobile: boolean = false;
  isGuest: boolean = false;
  isSameDate: boolean = false;

  urlsWarehouseMaps: string = '';

  dapp!: string | null;

  dateUtiliArray!: NgbDateStruct[];

  isDisabled;

  constructor(
    private _apiService: ApiService,
    private _appointmentService: AppointmentService,
    private _deviceService: DeviceService,
    private _fb: UntypedFormBuilder,
    private _gtmService: GtmService,
    private _guestService: GuestService,
    private _ordiniService: OrdiniService,
    private _route: RouteService,
    private _routeService: RouteService,
    private _trasportoMontaggioModalService: TrasportoMontaggioModalService,
    private _utilityService: UtilityService,
    private calendar: NgbCalendar,
    private dateAdapter: NgbDateAdapter<string>,
    private route: ActivatedRoute,
    private viewContainerRef: ViewContainerRef
  ) {
    this.initForm();
    this.isGuest = this._apiService.isGuest$.getValue();
    this.isLogged = this._apiService.isLogged$.getValue();
    this.grandTotal$ = this._apiService.grandTotal$;
    this.order$ = this._apiService.order$;
    this.shippingCost$ = this._apiService.shippingCost$;
    this.subtotal$ = this._apiService.subtotal$;

    this.isDisabled = (
      date: NgbDateStruct
    ) => {
      /** Nota bene:
       * itero sulle date che devono essere visualizzate,
       * è stata invertita l'assegnazione true false
       * per disabilitare le non presenti
       * */
      return this.dateUtiliArray.find(x =>
        (new NgbDate(x.year, x.month, x.day).equals(date))
      ) ? false : true;
    };

    this.isMobile = this._deviceService._isMobile.getValue();
  }

  changeDate(event: any) {
    let formattedOrderDapp = undefined;
    if (!event) {
      this.isSameDate = true;
    } else {
      const dappSplit = event.split('-');
      const formattedDapp = `${dappSplit[0].toString().padStart(2, '0')}-${(dappSplit[1]).toString().padStart(2, '0')}-${(dappSplit[2])}`;

      if (this.order.dapp != null) {
        const [date, time]: string[] = (this.order.dapp!).split('T');
        const orderDappSplit = date.split('-');
        formattedOrderDapp = `${orderDappSplit[2]}-${(orderDappSplit[1])}-${(orderDappSplit[0])}`;
      }

      this.isSameDate = (formattedDapp === formattedOrderDapp);
    }

  }

  ngOnInit(): void {    
    this.orderId = this.route.snapshot.params['orderId'];
    if (this.orderId) {
      let params: Params;

      this.route.params.subscribe((response) => {
        params = response;
      });

      this._apiService.orders$.subscribe((response: Order[]) => {
        const order = this._utilityService.getOrderDetailByParams(params, response);
        if (!order) throw new Error('Order not exist');
        this.urlsWarehouseMaps = this._utilityService.getUrlCoordinates(order);
        this.order = order;
        this.dateUtiliArray = this.dateArrayToNgbDate(this.order.dateutili);
        this.minDate = this.dateUtiliArray[0];
        this.maxDate = this.dateUtiliArray[this.dateUtiliArray.length - 1];
        if (order.dapp != null) {
          const dateSplitted = (order.dapp.split("T"))[0].split('-');
          this.dapp = this.dateAdapter.toModel({
            year: parseInt(dateSplitted[0]),
            month: parseInt(dateSplitted[1]),
            day: parseInt(dateSplitted[2])
          });
          this.isSameDate = true;
        } else {
          this.isSameDate = false;
        }

        this.order$.next(order);
      });
    }
  }

  openModalTM(e: Event) {
    e.preventDefault();
    this._trasportoMontaggioModalService.setRootViewContainerRef(this.viewContainerRef);
    this._trasportoMontaggioModalService.addDynamicComponent();
  }

  initForm() {
    this.reservationDataForm = this._fb.group({
      dateReservation: [('')],
    });
  }


  isWeekend(date: NgbDateStruct) {
    const d = new Date(date.year, date.month - 1, date.day);
    return d.getDay() === 0;
  }

  cameBack() {
    this._route.back();
  }

  submitReservation() {
    const dateSelected = this.reservationDataForm.value.dateReservation.split('-');
    const newApp = `${dateSelected[2]}${(dateSelected[1]).toString().padStart(2, '0')}${(dateSelected[0]).toString().padStart(2, '0')}`
    this.cbkAppointmentService(newApp);
  }

  deleteReservation() {
    this.closeModal();
    this.dapp = null;
    const newApp = '';
    this.cbkAppointmentService(newApp, true);
  }

  cbkAppointmentService(newApp: string, deleteApp: boolean = false) {
    this._appointmentService.cbkAppointment({
      guid: this.order.guid,
      shippingDate: this.dateTimezoneToStr(this.order.dcci),
      shippingType: this.order.consegnaKey,
      oldApp: this.dateTimezoneToStr(this.order.dapp),
      newApp: newApp,
    }).subscribe((response) => {
      if (!this._guestService.getGuid()) {
        this._ordiniService.cbkOrdini();
        this._apiService.isLogged$.next(true);
      } else {
        this._apiService.refreshCbkGuest();
      }

      this.dateAppFormatterd = `${this.dapp}`;

      if (deleteApp) this.openPopup('confirmDeleteAppointment');
      if (!deleteApp && this.order.consegnaKey !== 'R' && !this.isGuest) this.openPopup('confirmAppointment');
      if (!deleteApp && this.order.consegnaKey !== 'R' && this.isGuest) this.openPopup('confirmAppointmentGuest');
      if (!deleteApp && this.order.consegnaKey === 'R') this.openPopup('confirmAppointmentWarehouse');

      if (deleteApp) {
        this._gtmService.eventBookAppointmentCancelled();
      } else if (newApp) {
        if (!this.order.dapp) this._gtmService.eventBookAppointmentOrder(this.order.guid);
        if (this.order.dapp) this._gtmService.eventBookAppointmentChange();
      }
    });
  }

  private dateTimezoneToStr(dateTmz: string | null): string {
    if (dateTmz == '' || dateTmz == null) return '';

    const [date, time]: string[] = dateTmz.split('T');
    return date.replaceAll('-', '');
  }

  private dateArrayToNgbDate(dateUtiliStr: string) {
    return dateUtiliStr.split(',').map((e: string): NgbDateStruct => {
      return {
        "year": Number(e.substring(0, 4)),
        "month": Number(e.substring(4, 6)),
        "day": Number(e.substring(6))
      }
    })
  }

  changeShowModal() {
    this.showModal = !this.showModal;
  }
  closeModal() {
    this.showModal = false;
  }
  openPopup(type: string) {
    this.typePopup = type;
    this.changeShowModal();
  }
  goToOrder() {
    this._routeService.goTo('orders');
  }
}
